import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../../store';
import TextButton from '../../../../ui/buttons/TextButton/TextButton';
import { TEAM_TASK_NOTIFICATIONS } from '../../../../ui/components/TeamNotificationsModal/TeamTaskNotificationsModal';
import * as teamMode from '../../../../store/teamMode';
import { DislikeIcon, LikeIcon, SendIcon, TeamDoctorIcon } from '../../../../ui/icons';
import './style.scss';
import LotPanelActionsLTD from './LotPanelActionsLTD';
import classNames from '../../../../common/classNames';
import { LTD_ACTIONS, PLAYER_ROLE, REQUEST_TYPE, SICKNESS, SPECIALITIES } from '../../../../common/constants';
import { TASK_TYPES } from '../../../../store/constants';
import routes from '../../../../routes';

function LotPanelActions() {
  const { appStore } = RootStore;
  const isOutcoming = appStore.currentLotIsOutcoming;
  const isIncoming = appStore.currentLotIsIncoming;
  const isRevisioning = appStore.currentLotIsRevisioning;
  const isPendingRevisioning = appStore.currentLotIsPendingRevisioning;
  const isCounter = appStore.currentLotIsCounter;
  const isCompleted = appStore.currentLotIsCompleted;
  let disabled = !appStore.currentPlayer?.lot;
  const lot_id = appStore.currentPlayer?.lot?.lot_id;
  const isSpectatorModeOn = appStore.isSpectatorModeOn;
  const groupedCats = appStore.groupedLotCats;
  // TEAM MODE
  const isTeamMode = appStore.isTeamMode;
  const isLotContentEmpty = isTeamMode && lot_id && !appStore.currentPlayer.lot.contents.length;
  let currentAction = appStore.currentAction();
  if (!disabled && isTeamMode && !currentAction) {
    console.error('Текущий игрок не может совершать действия с этой задачей');
    disabled = true;
    currentAction = { isCreateAction: true };
  }
  // ToDo Информация по складу для LTD
  const liabilityLTD = appStore.currentLiabilityLTD;

  let task = null;
  if (isTeamMode) {
    const task_id = appStore.currentPlayer.lot?.task_id;
    if (task_id) {
      task = appStore.taskById(task_id);
    }
  }
  let deal_id;
  let owner;
  let ownerSpeciality;
  if (appStore.isDoctor && appStore.isCatStorage) {
    deal_id = appStore.currentDoctorReviewingLTD?.deal_id;
    owner = appStore.ltdById(deal_id)?.ltd_info?.message_owner;
    ownerSpeciality = appStore.companyPlayerById(owner)?.specialty;
  }
  function requestDoctor() {
    if (appStore.isSpectatorModeOn) {
      appStore.openSpectatorModal();
      return;
    }
    appStore.sendRequestDoctorAction(task?.task_id, task?.content);
    appStore.teamTaskNotificationsModalData = { title: TEAM_TASK_NOTIFICATIONS.DOCTOR, cats: content?.contents };
  }
  function cancelTaskDecision() {
    if (appStore.isSpectatorModeOn) {
      appStore.openSpectatorModal();
      return;
    }
    if (currentAction.name === teamMode.PRIVILEGES.APPROVE_BY_DOCTOR) {
      appStore.sendMessageActionsOnTask(
        task.task_id,
        task.content,
        false,
        null,
        TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
      );
    } else {
      appStore.sendMessageActionsOnTask(task.task_id, task.content, false, null, TASK_TYPES._ANY_TASK);
    }
    appStore.currentPlayer.lot = null;
  }
  const isLotAllReadyCancelledByMe = () => {
    return appStore.tasks.find(
      (task) =>
        task.content?.lot_id === lot_id &&
        task.type === TASK_TYPES.CANCEL_OWN_TRADE_REQUEST &&
        task.status === 'inprogress',
    );
  };

  const isLotAllReadyCancelled = () => {
    const task_id = appStore.currentPlayer.lot?.task_id;
    if (task_id) {
      return appStore.tasks.find((task) => task.task_id === task_id && task.status === 'cancelled');
    } else {
      return false;
    }
  };

  const isTaskAllReadyCancelled = () => {
    return task && task.status !== 'inprogress';
  };

  const isThisLotAllreadyReactedByMe = () => {
    const currentLot = appStore.currentPlayer.lot;
    return (
      currentLot?.lot_id &&
      !task &&
      appStore.relatedTaskByLotId(currentLot.lot_id, false)?.task_id &&
      currentLot?.updater !== appStore.me.uid &&
      !appStore.currentLotIsRevisioning
    );
  };

  const onCancel = useCallback(() => {
    if (!disabled) {
      appStore.cancelLot();
    }
  }, [disabled]);

  const onAccept = useCallback(() => {
    if (!disabled) {
      appStore.acceptLot();
    }
  }, [disabled]);

  const sickCatsFound = useMemo(
    () =>
      !!appStore.groupedLotCats?.reduce(
        (sum, cat) =>
          cat.contents?.reduce(
            (sumContent, catContent) =>
              sumContent +
              (catContent.detail?.sickness !== SICKNESS.NONE && catContent.detail?.wasViewedUnderMagnifier),
            0,
          ) + sum,
        0,
      ),
    [appStore.groupedLotCats],
  );

  if (appStore.mySpeciality === SPECIALITIES.DOCTOR && !task?.on_approve_by_doctor && task) {
    RootStore.goTo(routes.main);
  }

  let content = null;
  const fromDoctorWithPrivileges =
    appStore.withPrivilegesChange &&
    task &&
    task.stage !== appStore.mySpeciality &&
    task.specialty_by === SPECIALITIES.DOCTOR &&
    task.content.request_type === REQUEST_TYPE.BUY;

  if (fromDoctorWithPrivileges) {
    disabled = true;
  }
  if (
    liabilityLTD &&
    (liabilityLTD.action_type === LTD_ACTIONS.TO_STORAGE ||
      liabilityLTD.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT)
  ) {
    return <LotPanelActionsLTD />;
  } else if (appStore.currentDoctorReviewingLTD) {
    const typeLtd = appStore.currentDoctorReviewingLTD.ltd_type;

    const likeIconVisibleFlag =
      !appStore.currentDoctorReviewingLTD.content.contents.find((cat_info) => {
        return cat_info?.contents?.find(
          (cat) =>
            cat.sickness !== SICKNESS.NONE &&
            (appStore.isCatExaminationInLTD(cat.cat_id) ||
              (appStore.me.actionLTD?.actionDetail.action_type !== LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT &&
                appStore.isCatExamined(cat.cat_id))),
        );
      }) &&
      appStore.currentDoctorReviewingLTD.content.contents.some((cat_info) => {
        return cat_info.factCount > 0;
      });

    return (
      <>
        {likeIconVisibleFlag ? (
          <TextButton
            isDisabled={!!groupedCats && groupedCats.length > 0 ? false : true}
            title={<LikeIcon style={{ fill: '#FDF6EE' }} />}
            color="green"
            onClick={() => {
              appStore.sendLTDDoctorApproveEvent(appStore.currentDoctorReviewingLTD.deal_id, typeLtd, true);
              appStore.setTeamTaskNotificationsModal({
                latestAction: {
                  ...appStore.currentDoctorReviewingLTD,
                  params: {
                    contents: appStore.currentDoctorReviewingLTD.content.contents,
                  },
                  owner: {
                    title: ownerSpeciality,
                  },
                },
              });
            }}
          />
        ) : (
          <TextButton
            title={<DislikeIcon style={{ fill: '#FDF6EE' }} />}
            color="red"
            onClick={() => {
              appStore.sendLTDDoctorApproveEvent(appStore.currentDoctorReviewingLTD.deal_id, typeLtd, false);
              appStore.setTeamTaskNotificationsModal({
                latestAction: {
                  ...appStore.currentDoctorReviewingLTD,
                  params: {
                    contents: appStore.currentDoctorReviewingLTD.content.contents,
                  },
                  owner: {
                    title: ownerSpeciality,
                  },
                },
              });
            }}
          />
        )}
      </>
    );
  } else if (disabled) {
    return null;
  } else {
    if (isTeamMode || (appStore.isCorpMode && appStore.meIsCEO)) {
      return (
        <div className="lot-panel__actions">
          {(!currentAction.isOnlyCancelAction && !currentAction.isCancelActionByCaptain && !isPendingRevisioning) ||
          currentAction.toRevisionWithPrivileges ? (
            <>
              {!currentAction.isCreateAction || currentAction.toRevisionWithPrivileges ? (
                <>
                  {(appStore.mySpeciality !== SPECIALITIES.DOCTOR || sickCatsFound) && (
                    <TextButton
                      onClick={() => appStore.acceptLot(false)}
                      title={<DislikeIcon style={{ fill: '#FDF6EE' }} />}
                      color="red"
                      isDisabled={
                        isLotContentEmpty ||
                        isLotAllReadyCancelled() ||
                        isTaskAllReadyCancelled() ||
                        isThisLotAllreadyReactedByMe()
                      }
                    />
                  )}
                  <TextButton
                    onClick={() => {
                      if (!task || appStore.approveOfTheTaskIsValid(task)) {
                        appStore.acceptLot(true);
                      }
                    }}
                    title={<LikeIcon style={{ fill: '#FDF6EE' }} />}
                    color="green"
                    isDisabled={
                      isLotContentEmpty ||
                      isLotAllReadyCancelled() ||
                      isTaskAllReadyCancelled() ||
                      isThisLotAllreadyReactedByMe()
                    }
                  />
                </>
              ) : (
                <TextButton
                  title={<SendIcon style={{ fill: '#FDF6EE' }} />}
                  color="green"
                  onClick={() => appStore.acceptLot(true)}
                  customClass={'training-create-lot-button'}
                  isDisabled={isLotContentEmpty}
                />
              )}
              {appStore.hasSpecialities([SPECIALITIES.CAPTAIN, SPECIALITIES.SUPPLY, SPECIALITIES.SALES]) &&
                appStore.needDoctorForCurrentTask &&
                !isPendingRevisioning &&
                (!task?.visible_to.find((spec) => spec === SPECIALITIES.DOCTOR) ||
                  (!task.on_approve_by_doctor && task.visas?.doctor === undefined)) && (
                  <TextButton
                    onClick={requestDoctor}
                    title={<TeamDoctorIcon isActive={true} />}
                    color="blue"
                    isDisabled={isLotContentEmpty || isLotAllReadyCancelled() || isThisLotAllreadyReactedByMe()}
                  />
                )}
            </>
          ) : currentAction.isOnlyCancelAction || isPendingRevisioning ? (
            <TextButton
              title={<DislikeIcon style={{ fill: '#FDF6EE' }} />}
              color="red"
              isDisabled={!task?.content?.lot_id && isLotAllReadyCancelledByMe()}
              onClick={() => (task?.task_id && !isPendingRevisioning ? cancelTaskDecision() : appStore.cancelLot())}
            />
          ) : (
            <>
              <TextButton title={'НЕТ'} color="grey" onClick={() => appStore.acceptLot(false)} />
              <TextButton title={'ДА'} color="red" onClick={() => appStore.acceptLot(true)} />
            </>
          )}
        </div>
      );
    } else {
      return (
        <>
          {!lot_id || isRevisioning ? (
            <TextButton
              title={<SendIcon style={{ fill: '#FDF6EE' }} />}
              color="green"
              onClick={onAccept}
              customClass={'training-create-lot-button'}
            />
          ) : null}
          {(isOutcoming || isPendingRevisioning) && (
            <TextButton title={<DislikeIcon style={{ fill: '#FDF6EE' }} />} color="red" onClick={onCancel} />
          )}
          {isIncoming || isCounter ? (
            <div className="lot-area__lot-panel-btn-wrapper">
              <TextButton
                isDisabled={!!groupedCats && groupedCats.length > 0 ? false : true}
                title={<LikeIcon style={{ fill: '#FDF6EE' }} />}
                color="green"
                onClick={onAccept}
              />
              <TextButton title={<DislikeIcon style={{ fill: '#FDF6EE' }} />} color="red" onClick={onCancel} />
            </div>
          ) : null}{' '}
          {isCompleted && isSpectatorModeOn ? (
            <TextButton title={'СКРЫТЬ'} color="blue" onClick={() => appStore.hideLot(lot_id)} />
          ) : null}
        </>
      );
    }
  }
}

export default observer(LotPanelActions);
