import React, { useEffect, useMemo } from 'react';
import useWindow from '../../hooks/useWindow';
import CoinIcon from '../../icons/CoinIcon';
import './style.scss';
import DragableItem from '../../../modules/deal/DragableItem';
import { observer } from 'mobx-react';
import classNames from '../../../common/classNames';
import RootStore from '../../../store';
import Price from './Price';
import Info from './Info';
import { draggableOptionsDefault } from './draggableOptionsDefault';
import { DropContainer } from '../../../modules/deal/DropContainer';
import DeleteCatsIcon from '../../icons/DeleteCatsIcon';
import { getCatTypeText } from '../../../common/texts/catType';
import useDoubleClick from 'use-double-click';
import trainingStore from '../../../store/trainingStore';
import corpStore from '../../../store/CorpStore';
import Home from './Home';
import CatDescription from './CatDescription';
import QuestionMark from '../../icons/QuestionMark';
import {
  AI_TYPE,
  DRAG_AREA,
  DRAGGABLE_ITEM_TYPE,
  PLAYER_ROLE,
  ROOT_PATHS,
  SICKNESS,
  SPECIALITIES,
} from '../../../common/constants';
import draggableItemDefaultCanDrag from '../../../common/draggableItemDefaultCanDrag';
import * as teamMode from '../../../store/teamMode';
import { TASK_TYPES, TEAM_TASK_STATUS } from '../../../store/constants';

export const CAT_STATE = {
  DEFAULT: 'default',
  SINGLE: 'single',
  LOT: 'lot',
  ABSTRACT: 'abstract',
  NURSERY: 'nursery',
  STORAGE_MY_CATS: 'storage_my_cats',
  STORAGE: 'storage',
  TRANSPORTATION: 'transportation',
  TRANSFER: 'transfer',
};

export const BOTTOM_TEXT = {
  AGE: 'age',
  KIND: 'kind',
};

function Cat({
  from,
  cat,
  state = CAT_STATE.DEFAULT,
  checkAvailable = false,
  notShowCount = false,
  notShowPrices = false,
  onClick,
  onDoubleClick,
  onDeleteClick,
  bottomText,
  dragAround,
  companyIsBlocked = false,
  readonly,
  fakeReadonly = false,
  forceViewSickness,
  hideBackground,
  pinkBackground,
  draggableOptions,
  className,
  realCount,
  showHouseIcon = false,
  forceHouseIconView = false,
  average,
  customCount,
  ...rest
}) {
  const { appStore } = RootStore;
  const { isMobile } = useWindow();
  const clickRef = React.useRef();

  if (cat === undefined) {
    return;
  }

  const isFromHouse = !!appStore.catHouseNumber(cat.detail?.cat_id ?? cat.cat_id);

  if (appStore.isTeamMode) {
    if (
      from &&
      (from === DRAG_AREA.NURSERY_CATS || from.startsWith('nursery-house-')) &&
      !appStore.hasSpecialities([SPECIALITIES.DOCTOR])
    ) {
      readonly = true;
    }
  }

  let count = `×${cat.count || 0}`;
  // ToDo Для склада LTD
  let needCount = `${cat.needCount || 0}`;
  let factCount = `×${cat.factCount || 0}`;
  if (cat.count === null) {
    count = '∞';
  }
  if (!!customCount) {
    count = `×${customCount}`;
  } else if (state === CAT_STATE.SINGLE && !realCount) {
    count = `×1`;
  }

  const isCatWithQuestionMark = useMemo(() => {
    if (from === DRAG_AREA.STORAGE) {
      return cat.factCount === 0;
    }
    return false;
  }, [from, cat, cat.factCount]);

  const incompleteQuantity = useMemo(() => {
    if (from === DRAG_AREA.STORAGE) {
      return cat.needCount !== cat.factCount;
    }

    return false;
  }, [from, cat.needCount, cat.factCount]);

  useDoubleClick({
    onSingleClick: (event) => {
      if ((event && event.stopHandle) || (clickRef.current && clickRef.current.stopHandle)) {
        clickRef.current.stopHandle = false;
        return;
      }
      onClick && onClick();
    },
    onDoubleClick: (event) => {
      if (event.stopHandle) {
        return;
      }
      if (trainingStore.currentStep?.isDisabledDoubleClickCat) {
        return;
      }
      if (trainingStore.currentStep?.isDisabledDoubleClickInLotCat && from === 'lot') {
        return;
      }
      if (trainingStore.currentStep?.draggableSettingsOverride) {
        const draggableOptions = draggableOptions || draggableOptionsDefault;
        const canDrag = draggableItemDefaultCanDrag({
          draggableOptions,
          type: DRAGGABLE_ITEM_TYPE.CAT,
          data: cat,
          element: clickRef,
          dragAround,
          from,
        });
        if (!canDrag) {
          return;
        }
      }

      const isLtdDisallowedDoubleClickSpot =
        (from === DRAG_AREA.SHOP || from === DRAG_AREA.NURSERY) &&
        appStore.currentPlayer.ai_type !== AI_TYPE.FIFTH_SHOP;

      const isLtdDisallowedDoubleClickRole =
        appStore.me.role === PLAYER_ROLE.SHOP
          ? (appStore.mySpeciality === SPECIALITIES.SALES && from === DRAG_AREA.SHOP) ||
            (appStore.mySpeciality === SPECIALITIES.SUPPLY && from === DRAG_AREA.NURSERY)
          : (appStore.mySpeciality === SPECIALITIES.SUPPLY && from === DRAG_AREA.SHOP) ||
            (appStore.mySpeciality === SPECIALITIES.SALES && from === DRAG_AREA.NURSERY);

      if (
        appStore.with_ltd &&
        !appStore.withDefaultDeal &&
        !appStore.isCorpMode &&
        isLtdDisallowedDoubleClickSpot &&
        (!appStore.isTeamMode || isLtdDisallowedDoubleClickRole)
      ) {
        appStore.openOnlyLtdAllowedModal();
      }

      onDoubleClick && onDoubleClick();
    },
    ref: clickRef,
    latency: 200,
  });

  // специально вынесено сюда, и выделена переменная, чтобы работала реактивность
  let isHungry = cat.detail ? cat.detail.hungry : cat.hungry;
  let isAdult = appStore.isAdultCat(cat);

  if (appStore.isRealTimeMode && state === CAT_STATE.LOT) {
    isHungry = cat.isHungry;
  }
  const showedSickness = appStore.showedSicknessCatIds.includes(cat.detail ? cat.detail.cat_id : cat.cat_id);
  const sickness = cat.detail ? cat.detail.sickness : cat.sickness;
  const matingStatus = cat.detail ? cat.detail.mating_status : cat.mating_status;

  //
  const catSicknessVision =
    (appStore.isCorpMode
      ? !appStore.meIsCEO && appStore.hasSpecialities([SPECIALITIES.DOCTOR])
      : appStore.hasSpecialities([SPECIALITIES.DOCTOR])) ||
    (appStore.isCorpMode && appStore.meIsCEO
      ? corpStore.catIsExaminedById(cat.cat_id)
      : appStore.showedSicknessCatIds.find(
          (sicknessCatId) => sicknessCatId === cat.cat_id || sicknessCatId === cat.detail?.cat_id,
        ));
  // TODO звуки больных котиков должны ещё зависить от catSicknessVision
  //
  let buyVision = true;
  let sellVision = true;
  let catCountVision = true;
  let isFakeWithPrice = false;
  let isFakeInLot = false;

  if (appStore.isTeamMode && !(appStore.isCorpMode && appStore.meIsCEO)) {
    buyVision = false;
    sellVision = false;
    isFakeInLot = true;
    // закоментил, потому что https://app.shortcut.com/alabugabusinesscats/story/6446/
    // catCountVision = false;
    // if (
    //   from === DRAG_AREA.CITY &&
    //   !appStore.hasSpecialities([teamMode.SPECIALITIES.SUPPLY, teamMode.SPECIALITIES.SALES])
    // ) {
    //   catCountVision = true;
    // }
    if (state === CAT_STATE.LOT) {
      if (
        appStore.currentPlayer?.lot &&
        ((appStore.hasSpecialities([SPECIALITIES.SUPPLY]) && appStore.me.uuid === appStore.currentPlayer.lot.buyer) ||
          (appStore.hasSpecialities([SPECIALITIES.SALES]) && appStore.me.uuid !== appStore.currentPlayer.lot.buyer) ||
          appStore.hasSpecialities([SPECIALITIES.MARKETEER, SPECIALITIES.CAPTAIN]))
      ) {
        isFakeInLot = false;
      }
      if (
        appStore.hasSpecialities([SPECIALITIES.MARKETEER, SPECIALITIES.CAPTAIN]) &&
        !teamMode.playerHasPrivileges(appStore.myPrivileges, [teamMode.PRIVILEGES.CREATE_SALE_TASK], true)
      ) {
        isFakeWithPrice = true;
      }
    } else {
      if (
        appStore.hasSpecialities([SPECIALITIES.CAPTAIN]) ||
        appStore.hasSpecialities([SPECIALITIES.MARKETEER]) ||
        appStore.hasSpecialities([SPECIALITIES.SALES, SPECIALITIES.SUPPLY], true)
      ) {
        buyVision = true;
        sellVision = true;
      } else if (appStore.hasSpecialities([SPECIALITIES.SALES])) {
        sellVision = from === appStore.me.role || from === 'fake';
        buyVision = !sellVision;
      } else if (appStore.hasSpecialities([SPECIALITIES.SUPPLY])) {
        buyVision = from === appStore.me.role || from === 'fake';
        sellVision = !buyVision;
      }
    }
  } else if (appStore.isCorpMode && appStore.meIsCEO) {
    isFakeWithPrice = true;
  }

  if (cat.sell_price === 0) {
    sellVision = false;
    readonly = true;
  }

  if (companyIsBlocked) {
    buyVision = false;
    sellVision = false;
  }

  if (notShowPrices) {
    buyVision = !notShowPrices;
    sellVision = !notShowPrices;
  }

  if (state === CAT_STATE.TRANSPORTATION || state === CAT_STATE.TRANSFER) {
    checkAvailable = false;
    buyVision = true;
    sellVision = true;
  }

  if (appStore.meIsCEO && !(appStore.withHideInformationMechanics && appStore.withHideInformation)) {
    buyVision = true;
    sellVision = true;
    // ToDo feel BCC-447 избавиться на release 3.1.0
    if (appStore.router.currentRoute.rootPath === ROOT_PATHS.STORAGE) {
      readonly = false;
    }
  }

  if (state === CAT_STATE.STORAGE_MY_CATS && !cat.price) {
    checkAvailable = true;
  }

  if (fakeReadonly) {
    checkAvailable = false;
    readonly = false;
  }

  const catTemplate = (isDragging, dragRef, dropRef) => (
    <div
      ref={(element) => {
        if (!clickRef.current) {
          clickRef.current = element;
        }
        if (dragRef) {
          dragRef(element);
        }
        if (dropRef) {
          dropRef(element);
        }
      }}
      className={classNames({
        cat: true,
        [`cat--${state}`]: true,
        [`cat--${cat.gender}-${cat.color}`]: true,
        'cat--not-available': (checkAvailable && !cat.available) || !!fakeReadonly,
        'cat--readonly': readonly,
        'cat--hide-background': hideBackground,
        'cat--pink-background': pinkBackground,
        'cat--hungry': isHungry,
        [`cat--mating-${matingStatus}`]: matingStatus, // rested/mating/tired
        [`cat--sickness-${sickness}`]:
          (showedSickness || forceViewSickness) && catSicknessVision && sickness !== SICKNESS.NONE,
        [`cat--sickness`]: (showedSickness || forceViewSickness) && catSicknessVision && sickness !== SICKNESS.NONE,
        'cat--adult': isAdult,
        // {BCC-986} сделано на будущее. При наличии статуса добавляется класс "cat--with-status-mark",
        // который центрирует иконку.
        // При необходимости изменения каких-либо стилей добавляется новый класс на подобии "cat--with-question-mark"
        'cat--with-status-mark': isCatWithQuestionMark,
        'cat--with-question-mark': isCatWithQuestionMark,
        'cat--incomplete-quantity': incompleteQuantity,
        ...(className || {}),
      })}
      {...rest}
    >
      {isCatWithQuestionMark && <QuestionMark />}
      {!checkAvailable && !notShowCount && catCountVision && state !== CAT_STATE.NURSERY && (
        <span className="cat__count notranslate">{count}</span>
      )}
      {((isFromHouse && showHouseIcon) || forceHouseIconView) && <Home cat={cat} />}
      {state === CAT_STATE.STORAGE_MY_CATS && <span className="cat__count notranslate">{count}</span>}
      {state === CAT_STATE.STORAGE && (
        <span className="cat__count notranslate">
          {factCount}/{needCount}
        </span>
      )}
      {(state === CAT_STATE.LOT || state === CAT_STATE.TRANSPORTATION || state === CAT_STATE.TRANSFER) && (
        <span
          onClick={() => {
            clickRef.current.stopHandle = true;
            onDeleteClick();
          }}
          className={classNames({
            cat__delete: true,
            'training-disabled': trainingStore.currentStep?.isDisabledAllDeleteCat,
          })}
        >
          <DeleteCatsIcon />
        </span>
      )}
      <span className={classNames({ cat__image: true, [`cat__image--${cat.gender}-${cat.color}`]: true })} />
      {state === CAT_STATE.DEFAULT && (
        <Info buyVision={buyVision} sellVision={sellVision} buy_price={cat.buy_price} sell_price={cat.sell_price} />
      )}
      {!isMobile && (state === CAT_STATE.LOT || state === CAT_STATE.TRANSFER) && (
        <Price
          isFake={isFakeInLot}
          isFakeWithPrice={isFakeWithPrice}
          readonly={readonly}
          price={cat.price}
          oldPrice={cat.old_price}
          color={appStore.getPriceLotColor(cat)}
          onMinus={() => appStore.changePriceInLot(cat.color, cat.gender, cat.price - 1)}
          onPlus={() => appStore.changePriceInLot(cat.color, cat.gender, cat.price + 1)}
        />
      )}
      {isMobile && (state === CAT_STATE.LOT || state === CAT_STATE.TRANSFER) && !appStore.currentDoctorReviewingLTD && (
        <div
          className={classNames({
            'price-value': true,
            [`price-value--color-${appStore.getPriceLotColor(cat)}`]: true,
          })}
        >
          <CoinIcon />
          <span className="price-value__cost">
            <span className="notranslate">{cat.price}</span>
            {cat.old_price && <span className="price__old-price notranslate">{cat.old_price}</span>}
          </span>
        </div>
      )}
      {state === CAT_STATE.SINGLE && (
        <>
          {bottomText === BOTTOM_TEXT.AGE && <CatDescription cat={cat} />}
          {bottomText === BOTTOM_TEXT.KIND && (
            <div className="cat__description cat__description--info">{getCatTypeText(cat, '\n')}</div>
          )}
        </>
      )}
      {state === CAT_STATE.STORAGE_MY_CATS && <Info buyVision={false} sellVision={cat.price} sell_price={cat.price} />}
      {!isMobile && state === CAT_STATE.STORAGE && (
        <Price
          isFake={state === CAT_STATE.STORAGE && appStore.currentDoctorReviewingLTD}
          readonly={true}
          price={cat.price}
          withCoin={!!cat.price}
        />
      )}
      {isMobile && state === CAT_STATE.STORAGE && (
        <div
          className={classNames({
            'price-value': true,
          })}
        >
          <CoinIcon />
          <span className="price-value__cost">
            <span className="notranslate">{cat.price}</span>
          </span>
        </div>
      )}
    </div>
  );

  if (readonly && cat.sell_price > 0) {
    return (
      <DropContainer
        type={DRAG_AREA.CAT}
        dropData={cat}
        acceptType={'button'}
        element={({ canDrop, drop }) => catTemplate(null, null, drop)}
      />
    );
  }

  return (
    <DropContainer
      type={DRAG_AREA.CAT}
      dropData={cat}
      acceptType={'button'}
      element={({ canDrop, drop }) => (
        <DragableItem
          data={cat}
          from={from}
          dragAround={dragAround}
          draggableOptions={draggableOptions || draggableOptionsDefault}
          element={(isDragging, dragRef) => catTemplate(isDragging, dragRef, drop)}
        />
      )}
    />
  );
}

export default observer(Cat);
