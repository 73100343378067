import RootStore from '../../../store';
import { CoinIcon, TriangleIcon } from '../../icons';
import TextButton from '../../buttons/TextButton/TextButton';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import NumberInput from '../../NumberInput/NumberInput';
import { ALLOCATION_NOTIFICATION_TYPES, ALLOCATION_TYPES, CAR_TYPE } from '../../../store/constants';
import Car from '../Сar/Car';
import classNames from '../../../common/classNames';
import CatUrlImage from '../CatUrlImage';
import routes from '../../../routes';
import { LTD_ARCHIVE_ACTION_TYPE } from '../../../store/constants';
import ReactSelect from '../../Select/Select';
import useWindow from '../../hooks/useWindow';
import { LTD_CONTENT_NAME, LTD_CONTENT_TYPE } from '../../../common/constants';
import corpStore from '../../../store/CorpStore';

export const InputComponents = observer(({ captainId }) => {
  const { appStore } = RootStore;

  const [allocationData, setAllocationData] = useState({
    to: captainId,
    money: null,
  });

  function setMoneyToNull() {
    setAllocationData({ ...allocationData, money: null });
  }

  return (
    <div className="corporation-modal__city-actions">
      <NumberInput
        value={allocationData.money || ''}
        onChange={(value) => {
          if (value <= 99999) {
            setAllocationData({ ...allocationData, money: value });
          }
        }}
        className="corporation-modal__input"
      />
      <TextButton
        color="green"
        title="Выделить"
        onClick={() => {
          if (appStore.checkBalance(allocationData.money)) {
            corpStore.openBudgetAllocationConfirmationModal({
              captainId,
              money: allocationData.money,
              type: ALLOCATION_TYPES.ALLOCATION,
              callbackFn: setMoneyToNull,
            });
          } else {
            corpStore.openBudgetAllocationNotificationModal({
              captainId,
              money: allocationData.money,
              type: ALLOCATION_NOTIFICATION_TYPES.YOU_DONT_HAVE_ENOUGH_MONEY,
            });
          }
        }}
        isDisabled={!allocationData.money}
      />

      <TextButton
        color="red"
        title="Изъять"
        onClick={() => {
          if (corpStore.checkCaptainBalance(captainId, allocationData.money)) {
            corpStore.openBudgetAllocationConfirmationModal({
              captainId,
              money: allocationData.money,
              type: ALLOCATION_TYPES.WITHDRAWAL,
              callbackFn: setMoneyToNull,
            });
          } else {
            corpStore.openBudgetAllocationNotificationModal({
              captainId,
              money: allocationData.money,
              type: ALLOCATION_NOTIFICATION_TYPES.CAPTAIN_DONT_HAVE_ENOUGH_MONEY,
            });
          }
        }}
        isDisabled={!allocationData.money}
      />
    </div>
  );
});

export const BudgetAllocationTab = observer(({ onClose }) => {
  const { appStore } = RootStore;

  const corporationCaptains = corpStore.corporationCaptains.filter(
    (captain) => !captain.is_timed_out && captain.active,
  );

  if (!corporationCaptains.length) {
    return <div className="corporation-modal__transportation-text">У Вас нет активных городов</div>;
  }

  // ToDo: {BCC-49} {[Frontend] - компетенции в модальном окне выделения бюджета}
  const captainNameAndCompetenciesVisibility = false;

  return (
    <>
      <div className="corporation-modal__available-budget">
        <span className="corporation-modal__available-budget-text">Доступный бюджет</span>
        <CoinIcon style={{ height: '15px', width: '15px' }} />
        <span>{appStore.balance}</span>
      </div>
      {corporationCaptains.map((captain) => (
        <div key={captain.player_id} className="corporation-modal__city">
          <span className="corporation-modal__dividing-line" />
          <div className="corporation-modal__city-info">
            <div>Город {captain.areaNum}</div>
            <div
              style={{
                visibility: !captainNameAndCompetenciesVisibility && 'hidden',
              }}
            >
              {captain.captain_name}
            </div>

            <div
              style={{
                visibility: !captainNameAndCompetenciesVisibility && 'hidden',
              }}
            >
              К3 А1 О1
            </div>

            <div className="corporation-modal__city-info-money">
              {captain.balance} <CoinIcon />
            </div>
          </div>

          <InputComponents captainId={captain.player_id} />
        </div>
      ))}
      <div className="corporation-modal__body-actions corporation-modal__body-actions--horizontal">
        <TextButton color="blue" title="закрыть" onClick={onClose} />
      </div>
    </>
  );
});

export const TransactionsTab = observer(({ onClose }) => {
  const { appStore } = RootStore;
  const { isMobile } = useWindow();

  if (!corpStore.myActiveCompanies.length) {
    return <div className="corporation-modal__transportation-text">У Вас нет активных городов</div>;
  }
  const [dealData, setDealData] = useState({
    ceoUuid: null,
    areaNum: null,
    ltdContentType: null,
  });

  function setCEOUuid(CEOUuid) {
    if (!dealData.areaNum) {
      setDealData({ ...dealData, ceoUuid: CEOUuid });
    } else {
      const CEO = appStore.playerByUuid(CEOUuid);

      // == потому что у captain.areaNum str, а в dealDAta.areaNum - str. Не сделал === потому что в будущем areaNum у captain так же может быть str
      const companyOfCeoByAreaNum = corpStore.allCaptainsAreas.find(
        (captain) => captain.name === CEO.name && captain.active && captain.areaNum == dealData.areaNum,
      );

      if (companyOfCeoByAreaNum) {
        setDealData({ ...dealData, ceoUuid: CEOUuid });
      } else {
        const firstActiveAreaNumOfCurrentCEO = corpStore.allCaptainsAreas.find(
          (captain) => captain.name === CEO.name && captain.active,
        ).areaNum;
        setDealData({ ...dealData, ceoUuid: CEOUuid, areaNum: firstActiveAreaNumOfCurrentCEO });
      }
    }
  }

  const areaNums = dealData.ceoUuid
    ? corpStore.allActiveAreaNumsOfCEO(dealData.ceoUuid).filter((areaNum) => {
        return corpStore.allActiveAreaNums.find((_areaNum) => areaNum === _areaNum);
      })
    : corpStore.allActiveAreaNums;

  const anotherRoleCEO = dealData.areaNum
    ? corpStore
        .allCEOWithActiveCompanyInArea(dealData.areaNum, true)
        .filter((CEO) => !appStore.companyIsBlocked(CEO.uuid) && !appStore.meIsBlockedByCompany(CEO.uuid))
    : corpStore.anotherRoleCEO.filter(
        (CEO) => CEO.active && !appStore.companyIsBlocked(CEO.uuid) && !appStore.meIsBlockedByCompany(CEO.uuid),
      );

  const selects = [
    {
      title: 'Выберите СЕО',
      value: {
        label: appStore.playerByUuid(dealData.ceoUuid)?.name || 'Без СЕО',
        value: dealData.ceoUuid,
      },
      onChange: (event) => {
        setCEOUuid(event.value);
      },
      customClass: 'corporation-modal__deals-select--large',
      options: anotherRoleCEO.map((CEO) => ({
        value: CEO.uuid,
        key: CEO.uuid,
        label: CEO.name,
      })),
    },
    {
      title: 'Выберите город',
      value: {
        label: dealData.areaNum ? 'город ' + dealData.areaNum : 'Без города',
        value: dealData.areaNum,
      },
      onChange: (event) => {
        setDealData({ ...dealData, areaNum: event.value });
      },
      customClass: '',
      options: areaNums.map((area_num) => ({
        value: area_num,
        key: area_num,
        label: 'город ' + area_num,
      })),
    },
    {
      title: 'Вид сделки',
      value: {
        label: LTD_CONTENT_NAME[dealData.ltdContentType] || 'Без сделки',
        value: dealData.ltdContentType,
      },
      onChange: (event) => {
        setDealData({ ...dealData, ltdContentType: event.value });
      },
      customClass: 'corporation-modal__deals-select--large',
      options: Object.values(LTD_CONTENT_TYPE).map((ltdType) => ({
        value: ltdType,
        key: ltdType,
        label: LTD_CONTENT_NAME[ltdType],
      })),
    },
  ];
  return (
    <>
      <div className="corporation-modal__deals-header">Создание долгосрочной сделки</div>
      <div className="corporation-modal__deals-selects">
        {selects.map((select) => (
          <div className="corporation-modal__deal-selects-block" key={select.title}>
            <span className="corporation-modal__deals-select-label">{select.title}</span>
            <ReactSelect
              value={select.value}
              onChange={select.onChange}
              className={classNames({
                'corporation-modal__deals-select': true,
                [select.customClass]: !!select.customClass,
              })}
              options={select.options}
              maxMenuHeight={isMobile ? 60 : 120}
            />
          </div>
        ))}
      </div>
      <div className="corporation-modal__body-actions corporation-modal__body-actions--horizontal">
        <TextButton
          color="green"
          title="создать"
          customClass="corporation-modal__text-button"
          onClick={() => {
            if (appStore.LTDArchive(dealData.ceoUuid, dealData.ltdContentType)) {
              appStore.openLTDArchiveModal({
                partnerUuid: dealData.ceoUuid,
                actionType: LTD_ARCHIVE_ACTION_TYPE.LOAD,
                type_ltd: dealData.ltdContentType,
              });
            }
            RootStore.router.goTo(routes.longTermsDealsCorpNew, {
              uuid: dealData.ceoUuid,
              type_ltd: dealData.ltdContentType,
              area_num: dealData.areaNum,
            });
            corpStore.hideCorporationModal();
          }}
          isDisabled={
            !dealData.ceoUuid ||
            !dealData.ltdContentType ||
            !dealData.areaNum ||
            appStore.companyIsBlocked(dealData.ceoUuid) ||
            appStore.meIsBlockedByCompany(dealData.ceoUuid)
          }
        />
        <TextButton customClass="corporation-modal__text-button" color="blue" title="закрыть" onClick={onClose} />
      </div>
    </>
  );
});

export const TransportationTab = observer(({ onClose }) => {
  const transportations = corpStore.transportations;
  const myCars = corpStore.myCars;
  const Transportations = ({ transportation = null }) => {
    const [showTransportationDetail, setShowTransportationDetail] = useState(false);
    const transportationInfo = corpStore.transportationInfo(transportation.move_id);

    return (
      <>
        <div
          className="corporation-modal__transportation-row"
          onClick={() => setShowTransportationDetail(!showTransportationDetail)}
        >
          <TriangleIcon color="#C6A788" top={showTransportationDetail} />
          <span className="corporation-modal__transportation-row-info">
            Перевозка котиков из города {corpStore.areaNumByCaptainId(transportation.sender_city_captain_id)} в город{' '}
            {corpStore.areaNumByCaptainId(transportation.recipient_city_captain_id)}
          </span>
          <div className="corporation-modal__transportation-cars">
            <Car
              type={CAR_TYPE.SMALL}
              size="min"
              count={transportationInfo.cars[CAR_TYPE.SMALL].length}
              isAvailable={transportationInfo.cars[CAR_TYPE.SMALL].length > 0}
            />
            <Car
              type={CAR_TYPE.BIG}
              size="min"
              count={transportationInfo.cars[CAR_TYPE.BIG].length}
              isAvailable={transportationInfo.cars[CAR_TYPE.BIG].length > 0}
            />
          </div>
          <span className="corporation-modal__transportation-dividing-line" />
        </div>
        {showTransportationDetail && (
          <div className="corporation-modal__transportation-cats--wrapper">
            {transportationInfo.cats.map((catItem) => (
              <div
                key={catItem.gender + catItem.color}
                className={classNames({
                  'corporation-modal__transportation-cat': true,
                  'corporation-modal__transportation-cat--not-visible': !catItem.isVisible,
                })}
              >
                <span className="corporation-modal__transportation-cat-count">×{catItem.count}</span>
                <CatUrlImage color={catItem.color} gender={catItem.gender} />
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="corporation-modal__transportation-head">
        <span className="corporation-modal__transportation-text">Мои машины</span>
        <div className="corporation-modal__transportation-cars corporation-modal__transportation-cars--indent">
          <Car type={CAR_TYPE.SMALL} color="beige" count={myCars[CAR_TYPE.SMALL].length} />
          <Car type={CAR_TYPE.BIG} color="beige" count={myCars[CAR_TYPE.BIG].length} />
        </div>
      </div>
      <div className="corporation-modal__transportation-main">
        <span className="corporation-modal__transportation-text">Транспортировка котиков между своими городами</span>
        {!transportations.length ? (
          <span className="corporation-modal__transportation-text corporation-modal__transportation-text--not-bold">
            На данный момент нет активных транспортировок
          </span>
        ) : (
          <div className="corporation-modal__transportation-rows">
            {transportations.map((transportation) => (
              <Transportations key={transportation.move_id} transportation={transportation} />
            ))}
          </div>
        )}
      </div>
      <div className="corporation-modal__body-actions corporation-modal__body-actions--horizontal">
        <TextButton
          customClass="corporation-modal__text-button"
          color="green"
          title="перейти к перевозке"
          isDisabled={corpStore.myActiveCompanies.length < 2}
          onClick={() => {
            onClose();
            RootStore.router.goTo(routes.transportation);
          }}
        />
        <TextButton customClass="corporation-modal__text-button" color="blue" title="закрыть" onClick={onClose} />
      </div>
    </>
  );
});
