import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../../store';
import { ArrowIcon, MagnifierIcon } from '../../../../ui/icons';
import StorageImage from '../../../../ui/components/StorageImage';
import { LTD_ACTIONS, PLAYER_ROLE } from '../../../../common/constants';

function LotAreaInfo() {
  const { appStore } = RootStore;
  const lot = appStore.currentPlayer?.lot;
  const LTDActionType = appStore.me.actionLTD && appStore.me.actionLTD.actionDetail.action_type;
  const { title, subTitle, playerName } = appStore.lotInfoText;

  const TopLogo = appStore.me?.role === PLAYER_ROLE.SHOP ? appStore.me?.logo : appStore.currentPlayer?.logo;

  const BottomLogo = appStore.me?.role === PLAYER_ROLE.SHOP ? appStore.currentPlayer?.logo : appStore.me?.logo;
  const myAvatarStyle = { width: '28px', height: '28px' };

  if (!lot && !LTDActionType) {
    return (
      <div className="lot-area__info lot-area__info--transaction">
        <div className="lot-area__info__arrows">
          <ArrowIcon top={false} color="#FDF6EE" />
          <ArrowIcon color="#FDF6EE" style={{ transform: 'translateY(-20px)' }} />
        </div>

        <p className="lot-area__info__transaction-text">
          ЗОНА
          <br />
          СДЕЛКИ
        </p>
      </div>
    );
  }

  if (LTDActionType) {
    return (
      <div className="lot-area__info lot-area__info--transaction">
        <div className="lot-area__info-text lot-area__info-text--min">
          {LTDActionType === LTD_ACTIONS.TO_STORAGE ? (
            <span>
              отправь котиков <br />
              на склад
            </span>
          ) : (
            <span>
              проверь котиков <br />
              на складе
            </span>
          )}
        </div>
        <div className="lot-area__info__arrows lot-area__info__arrows--indent">
          <StorageImage />
          {LTDActionType === LTD_ACTIONS.TO_STORAGE ? (
            <ArrowIcon color="#FDF6EE" height="6vh" width="6vh" top={appStore.me.role === PLAYER_ROLE.NURSERY} />
          ) : (
            <MagnifierIcon size="6vh" />
          )}
        </div>
        <div className="lot-area__info-text">
          <span>Склад</span>
        </div>
      </div>
    );
  }

  if (title === 'ПРОДАЮ' || title === 'ПОКУПАЮ') {
    return (
      <div
        className="lot-area__info lot-area__info--active"
        style={{ flexDirection: appStore.currentPlayer.role === PLAYER_ROLE.SHOP ? 'column-reverse' : 'column' }}
      >
        <p className="lot-area-info__header">{title}</p>
        {TopLogo && <TopLogo style={appStore.me?.role === PLAYER_ROLE.SHOP ? myAvatarStyle : {}} />}
        <ArrowIcon
          height="6vh"
          width="6vh"
          color="#FDF6EE"
          top={
            (title === 'ПОКУПАЮ' && appStore.currentPlayer.role === PLAYER_ROLE.NURSERY) ||
            (title === 'ПРОДАЮ' && appStore.currentPlayer.role === PLAYER_ROLE.SHOP) ||
            (title === 'ПОКУПАЮ' && appStore.me.role === PLAYER_ROLE.NURSERY)
          }
        />
        {BottomLogo && <BottomLogo style={appStore.me?.role === PLAYER_ROLE.NURSERY ? myAvatarStyle : {}} />}
        <p className="lot-area-info__player-name">
          <span>{subTitle}</span>
          {playerName}
        </p>
      </div>
    );
  } else if (title === 'ПРОДАЕТ' || title === 'ПОКУПАЕТ') {
    return (
      <div className=" lot-area__info lot-area__info--active">
        {TopLogo && <TopLogo style={appStore.me?.role === PLAYER_ROLE.SHOP ? myAvatarStyle : {}} />}
        <ArrowIcon
          height="6vh"
          width="6vh"
          color="#FDF6EE"
          top={title === 'ПРОДАЕТ' && appStore.currentPlayer.role === PLAYER_ROLE.NURSERY}
        />
        {BottomLogo && <BottomLogo style={appStore.me?.role === PLAYER_ROLE.NURSERY ? myAvatarStyle : {}} />}
        <p className="lot-area-info__player-name">
          <span>{subTitle}</span>
          {playerName}
        </p>
        <p className="lot-area-info__header">{title}</p>
      </div>
    );
  }
}

export default observer(LotAreaInfo);
