import React from 'react';

function NurseryIcon({ style }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="#fff" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path d="M10.0935 11.3203H13.295V14.3477H10.0935V11.3203Z" fill="inherit" />
      <path d="M10.1755 22.9574H13.2136V29.9998H10.1755V22.9574Z" fill="inherit" />
      <path d="M21.0732 19.5579H24.2748V22.5852H21.0732V19.5579Z" fill="inherit" />
      <path
        d="M22.5109 11.3648C22.2593 11.3648 22.017 11.27 21.8322 11.0993L12.3431 2.3306C11.9727 1.98831 11.4053 1.97542 11.0198 2.30053L0.738636 10.9695C0.330694 11.3135 0.278826 11.923 0.622784 12.331L0.69953 12.422C1.00112 12.7797 1.53558 12.8252 1.89327 12.5236V28.9999C1.89327 29.5522 2.34099 29.9999 2.89327 29.9999H8.24323V22.0251C8.24323 21.4728 8.69094 21.0251 9.24323 21.0251H14.1461C14.6984 21.0251 15.1461 21.4728 15.1461 22.0251V29.9999H29.6033C30.1556 29.9999 30.6033 29.5521 30.6033 28.9999V13.2972H31.0338C31.5674 13.2972 32 12.8646 32 12.331C32 11.7974 31.5674 11.3648 31.0338 11.3648H22.5109ZM15.2278 15.2803C15.2278 15.8325 14.7801 16.2803 14.2278 16.2803H9.16149C8.60921 16.2803 8.16149 15.8325 8.16149 15.2803V10.3881C8.16149 9.83585 8.60921 9.38814 9.16149 9.38814H14.2278C14.7801 9.38814 15.2278 9.83585 15.2278 10.3881V15.2803ZM26.2073 23.5178C26.2073 24.0701 25.7596 24.5179 25.2073 24.5179H20.141C19.5887 24.5179 19.141 24.0701 19.141 23.5179V18.6257C19.141 18.0735 19.5887 17.6257 20.141 17.6257H25.2073C25.7595 17.6257 26.2072 18.0734 26.2073 18.6257L26.2073 23.5178Z"
        fill="inherit"
      />
    </svg>
  );
}

export default NurseryIcon;
