import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import { getGameUnitOfTime, MONTH_NAME } from '../../../common/calcTimeUnit';
import { TIMER_ID } from '../../../common/constants';
import Timer from '../Timer';

function HeaderTimer({ background }) {
  const { appStore } = RootStore;

  const textForCurrentSeason = () => {
    if (!appStore.isRealTimeMode) {
      return (
        <p className="season_text">
          <span className="notranslate">{appStore.currentSeason + 1}</span> <span>СЕЗОН</span>
        </p>
      );
    } else {
      const { month, year } = getGameUnitOfTime();
      return (
        <p className="season_text">
          <span>{MONTH_NAME[month]}</span> <span className="notranslate">{year}</span>
        </p>
      );
    }
  };

  return (
    <div className="season_container" style={!background ? { background: 'rgba(255, 255, 255, 0.5)' } : {}}>
      {textForCurrentSeason()}
      <Timer customClassName="time_text notranslate" id={TIMER_ID.HEADER.SESSION_TIMER}>
        {appStore.timerValue.$ms >= 0 && appStore.timerValue.format('HH:mm:ss')}
      </Timer>
    </div>
  );
}

export default observer(HeaderTimer);
