import React from 'react';
import DragableItem from '../../../modules/deal/DragableItem';
import RootStore from '../../../store';
import trainingStore from '../../../store/trainingStore';
import { observer } from 'mobx-react';
import useDoubleClick from 'use-double-click';
import classNames from '../../../common/classNames';
import EatIcon from '../../../ui/icons/EatIcon';
import { DRAG_AREA, DRAG_BUTTON_TYPE, DRAGGABLE_ITEM_TYPE } from '../../../common/constants';

function EatBtn({ checkActions, onClick }) {
  const { appStore } = RootStore;
  const currentGroupedHouses = appStore.currentGroupedHouses;
  const eatBtn = React.useRef();
  const doubleEatClick = () => {
    function feed(dropData) {
      appStore.dropItem({
        from: DRAG_AREA.BUTTONS,
        dropData: dropData,
        to: DRAG_AREA.CAT,
        type: 'button',
        data: { buttonType: DRAG_BUTTON_TYPE.EAT },
      });
    }
    if (currentGroupedHouses) {
      if (currentGroupedHouses.left.left_cat && currentGroupedHouses.left.left_cat.hungry) {
        feed(currentGroupedHouses.left.left_cat);
      } else if (currentGroupedHouses.left.right_cat && currentGroupedHouses.left.right_cat.hungry) {
        feed(currentGroupedHouses.left.right_cat);
      } else if (currentGroupedHouses.right.left_cat && currentGroupedHouses.right.left_cat.hungry) {
        feed(currentGroupedHouses.right.left_cat);
      } else if (currentGroupedHouses.right.right_cat && currentGroupedHouses.right.right_cat.hungry) {
        feed(currentGroupedHouses.right.right_cat);
      } else {
        appStore.openAllCatIsFullModal();
        return false;
      }
    }
  };

  useDoubleClick({
    onSingleClick: (event) => {
      if (appStore.isTeamMode && !appStore.buyEatModal) {
        onClick && onClick();
      }
    },
    onDoubleClick: (event) => {
      if (event.stopHandle) {
        return;
      }
      doubleEatClick && doubleEatClick();
    },
    ref: eatBtn,
    latency: 200,
  });

  const foodCount = appStore.goods.food;

  return (
    <DragableItem
      type={DRAGGABLE_ITEM_TYPE.BUTTON}
      from={DRAG_AREA.BUTTONS}
      data={{ buttonType: DRAG_BUTTON_TYPE.EAT }}
      draggableOptions={{
        canDrag: (data, from, element) => {
          return true;
        },
      }}
      element={(isDragging, dragRef) => {
        return (
          <p
            ref={(element) => {
              eatBtn.current = element;
              if (dragRef) {
                dragRef(element);
              }
            }}
            className={classNames({
              'own-nurseries__actions-item': true,
              'own-nurseries__actions-item--eat': true,
              'own-nurseries__actions-item--hidden':
                (trainingStore.launchedChapter > 0 && trainingStore.launchedChapter < 3) ||
                (trainingStore.launchedChapter === 4 && !trainingStore.currentTask) ||
                trainingStore.launchedChapter === 9,
              'own-nurseries__actions-item--disabled': !checkActions(),
            })}
          >
            <EatIcon stylePath={checkActions() ? {} : { fill: '#C6A788' }} />
            {appStore.isTeamMode && <div className="eat-btn__quantity">{foodCount}</div>}
          </p>
        );
      }}
    />
  );
}

export default observer(EatBtn);
