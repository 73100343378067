import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import TextButton from '../../buttons/TextButton/TextButton';
import IconButton from '../../buttons/IconButton/IconButton';
import HeaderTimer from './HeaderTimer';
import LogoutIcon from '../../icons/LogoutIcon';
import './style.scss';
import Settings from '../Settings';
import classNames from '../../../common/classNames';
import trainingStore from '../../../store/trainingStore';
import HeaderUserListButton from './HeaderUserListButton';
import AlabugaIconMain from '../../icons/AlabugaIconMain';
import HeaderChatButton from './HeaderChatButton';
import HeaderDelegatingButton from './HeaderDelegatingButton';
import HeaderTeamHistoryButton from './HeaderTeamHistoryButton';
import HeaderHideButton from './HeaderHideButton';
import SliderComponent from '../../../modules/slider/SliderComponent';
import corpStore from '../../../store/CorpStore';
import { SLIDER_THEME } from '../../../store/constants';
import UserAvatar from '../../../modules/UserAvatar/UserAvatar';
import getHeaderTitle from '../../../common/getHeaderTitle';
import { SPECIALITIES } from '../../../common/constants';
import api from '../../../api';
import PauseButton from '../Pause/PauseButton';

function Header({ extra, background }) {
  const { appStore } = RootStore;
  const Logo = appStore.me?.logo ?? null;

  const nameTitle = getHeaderTitle(appStore.me?.name);

  const areaNums = corpStore.allActiveAreaNums;
  const areaNumsLabels = areaNums.map((area) => 'Город ' + area);

  return (
    <div
      className={classNames({
        header_container: true,
        'header_container--disabled':
          trainingStore.launched && trainingStore.currentStep && trainingStore.currentStep.headerDisabled,
      })}
      style={!background ? { background: 'none', boxShadow: 'none' } : {}}
    >
      <div
        className={classNames({
          left_side_container: true,
          'left_side_container--ceo': appStore.meIsCEO,
        })}
      >
        <div className="header_container__main-left-side-container-content">
          {extra && <div className="header-extra">{extra}</div>}
          <UserAvatar onClick={appStore.openBalanceModal} name={nameTitle} Logo={Logo} balanceIsVisible={true} />
          {!appStore.meIsCEO && (
            <div className="main__alabuga-icon-mobile">
              <AlabugaIconMain />
            </div>
          )}
        </div>
        <div className="header_container__additional-left-side-container-content">
          {corpStore.headerChangeAreaSliderIsVisible && (
            <div className="header_container__area_nums">
              <SliderComponent
                sliderCustomClass="area-num-slider"
                sliderItemCustomClass="area-num-slider__item"
                labels={areaNumsLabels}
                afterChange={(current) => {
                  corpStore.setCurrentAreaNum(areaNums[current]);
                }}
                theme={SLIDER_THEME.LIGHT}
                currentSlideIndex={areaNums.indexOf(corpStore.currentAreaNum)}
              />
            </div>
          )}
        </div>
      </div>
      <HeaderTimer background={background} />
      <div className="btns_container">
        {/* TODO: сделать общий компонент кнопки */}
        {appStore.withHideInformationMechanics && <HeaderHideButton />}
        {appStore.isSpectatorModeOn && <PauseButton />}
        <HeaderChatButton />
        <Settings />
        <HeaderUserListButton />
        {(appStore.isTeamMode || appStore.isCorpMode) && <HeaderDelegatingButton />}
        {appStore.isTeamMode && <HeaderTeamHistoryButton />}
        {!appStore.isRealTimeMode && (
          <TextButton
            customClass="end-turn-button"
            color="red"
            title="ЗАВЕРШИТЬ СЕЗОН"
            onClick={appStore.exactlyAcceptEndTurn}
            isDisabled={!appStore.hasSpecialities(appStore.isCorpMode ? SPECIALITIES.CEO : SPECIALITIES.CAPTAIN)}
          />
        )}
        <IconButton
          color="purple"
          customClass={classNames({
            'session-logout-icon-button': true,
            'training-z-index':
              trainingStore.launched &&
              trainingStore.isAllowExit &&
              (trainingStore.currentStep || trainingStore.currentTask),
            disabled: trainingStore.launched && !trainingStore.isAllowExit,
          })}
          onClick={() => {
            appStore.backgroundMusic?.pause();
            if (trainingStore.currentTask) {
              api.common.setTutorialProgress(trainingStore.currentTask.chapter);
            }
            trainingStore.currentStep || trainingStore.currentTask
              ? trainingStore.stopTraining(true)
              : appStore.closeSession();
          }}
        >
          <LogoutIcon style={{ width: '24px', height: '24px', display: 'block' }} />
        </IconButton>
      </div>
    </div>
  );
}

export default observer(Header);
