import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import { CREDIT_TYPE_NAME, ROLE_NAME } from '../../store/constants';
import { CoinIcon } from '../../ui/icons';
import { declOfNum } from '../../common/declensionOfWords';
import UserAvatar from '../../modules/UserAvatar/UserAvatar';
import getHeaderTitle from '../../common/getHeaderTitle';
import getFormattedTimeFromSecond from '../../common/getFormattedTimeFromSecond';
import { SPECIALITIES_RUSSIAN_NAME, PLAYER_ROLE } from '../../common/constants';

const declensionForHours = ['час', 'часа', 'часов'];
const declensionForMinutes = ['минута', 'минуты', 'минут'];
const declensionForSeconds = ['секунда', 'секунды', 'секунд'];

function textFormatForMechanicRealtime(time) {
  const hour = Math.floor(time / 3600);
  const minute = Math.floor((time % 3600) / 60);
  const second = Math.floor((time % 3600) % 60);

  return (
    (hour > 0 ? hour + ' ' + declOfNum(hour, declensionForHours) + ' ' : '') +
    (minute > 0 ? minute + ' ' + declOfNum(minute, declensionForMinutes) + ' ' : '') +
    (second > 0 ? second + ' ' + declOfNum(second, declensionForSeconds) : '')
  );
}

const REALTIME_MECHANICS_TITLE = {
  GROWING_UP_CATS: 'Взросление котиков',
  MATING_CATS: 'Скрещивание котиков',
  HOUSE_INSURANCE: 'Страхование домика',
  REST_CATS: 'Отдых котиков',
  HUNGRY_CATS: 'Голодает котик через',
  HOMELESS_CATS: 'Без домика котик убегает через',
  RUNAWAY_CATS: 'От болезни или голода котик убегает через',
  RECOVERY_DEMAND: 'Восстановление спроса',
  UTILITIES: 'Оплата коммунальных услуг раз в',
  CREDIT: 'Оплата кредита раз в',
};

function StartSessionParameters() {
  const { appStore } = RootStore;
  // ToDo feed {BCC-126} - рефакторинг
  if (!appStore.me) {
    return;
  }

  const nursery = appStore.isCorpMode ? 'Корпорации питомника' : 'Питомник';
  const shop = appStore.isCorpMode ? 'Корпорации зоомагазина' : 'Зоомагазин';

  const russianRoleNames = {
    [PLAYER_ROLE.NURSERY]: nursery,
    [PLAYER_ROLE.SHOP]: shop,
  };

  let realtimeSessionDuration = 0;
  let REALTIME_MECHANICS_TIME = null;

  if (appStore.isRealTimeMode) {
    const realtimeThreeMonthDuration = appStore.monthDuration * 3;
    realtimeSessionDuration = Object.values(appStore.turnDurationsSec).reduce((a, b) => a + b, 0);
    REALTIME_MECHANICS_TIME = {
      [REALTIME_MECHANICS_TITLE.GROWING_UP_CATS]: realtimeThreeMonthDuration * 3,
      [REALTIME_MECHANICS_TITLE.MATING_CATS]: realtimeThreeMonthDuration,
      [REALTIME_MECHANICS_TITLE.HOUSE_INSURANCE]: realtimeThreeMonthDuration,
      [REALTIME_MECHANICS_TITLE.REST_CATS]: realtimeThreeMonthDuration,
      [REALTIME_MECHANICS_TITLE.HUNGRY_CATS]: realtimeThreeMonthDuration,
      [REALTIME_MECHANICS_TITLE.HOMELESS_CATS]: realtimeThreeMonthDuration,
      [REALTIME_MECHANICS_TITLE.RUNAWAY_CATS]: realtimeThreeMonthDuration / 3,
      [REALTIME_MECHANICS_TITLE.RECOVERY_DEMAND]: realtimeThreeMonthDuration,
      [REALTIME_MECHANICS_TITLE.UTILITIES]: realtimeThreeMonthDuration,
      [REALTIME_MECHANICS_TITLE.CREDIT]: realtimeThreeMonthDuration,
    };
  }

  let roleTitle;
  if (appStore.isCorpMode && appStore.meIsCEO) {
    roleTitle = `Вы играете за роль СЕО корпорации ${ROLE_NAME[appStore.me.role]}ов «${appStore.me.name}»`;
  } else if (appStore.isTeamMode) {
    roleTitle = `Вы играете за роль ${SPECIALITIES_RUSSIAN_NAME[appStore.mySpeciality]}а ${
      ROLE_NAME[appStore.me.role]
    }а «${appStore.me.name}»`;
  } else {
    roleTitle = `Вы играете за роль ${ROLE_NAME[appStore.me.role]} «${appStore.me.name}»`;
  }

  const minimalGameDuration = getFormattedTimeFromSecond(appStore.totalGameMinimalDuration);
  const fullGameDuration = getFormattedTimeFromSecond(appStore.totalGameDurationSec);

  const Parameters = [
    {
      title: 'Ваша роль',
      content: (
        <div>
          <div className="parameters-modal-information">
            <span className="parameters-modal__role-title">{roleTitle}</span>
          </div>
          <div className="parameters-modal-information">
            <UserAvatar
              Logo={appStore.me?.logo ?? null}
              name={getHeaderTitle(appStore.me?.name)}
              balanceIsVisible={false}
              useStyleAdaptation={false}
            />
          </div>
        </div>
      ),
    },
    {
      title: 'Баланс',
      content: (
        <div>
          <div className="parameters-modal-information">
            <span className="parameters-modal-balance">
              {nursery} - <span className="notranslate"> {appStore.startingParameters.nursery_start_balance} </span>
              <CoinIcon
                style={{ position: 'relative', top: '2px', marginLeft: '4px', height: '16px', width: '16px' }}
                alt="Монетка"
              />
            </span>
          </div>
          <div className="parameters-modal-information">
            <span className="parameters-modal-balance">
              {shop} - <span className="notranslate"> {appStore.startingParameters.shop_start_balance} </span>
              <CoinIcon
                style={{ position: 'relative', top: '2px', marginLeft: '4px', height: '16px', width: '16px' }}
                alt="Монетка"
              />
            </span>
          </div>
        </div>
      ),
    },
    {
      title: 'Коммунальные услуги',
      content: (
        <div>
          {Object.entries(appStore._gameCosts).map(([role, costs]) => {
            return (
              <div className="parameters-modal-information" key={role}>
                <span className="parameters-modal-balance">
                  {russianRoleNames[role]} - <span className="notranslate"> {costs.household} </span>
                  <CoinIcon
                    style={{ position: 'relative', top: '2px', marginLeft: '4px', height: '16px', width: '16px' }}
                    alt="Монетка"
                  />
                </span>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      title: appStore.isRealTimeMode ? 'временные рамки' : 'время сезона',
      content: !appStore.isRealTimeMode ? (
        <div className="parameters-modal-main-time-season">
          <div className="parameters-modal-time-season__column">
            <span className="parameters-modal-season">
              <b>Сезоны</b>
            </span>
            {appStore.turnDurationsSec.map((item, index) => {
              return (
                <span key={index + ' ' + item} className="parameters-modal-season notranslate">
                  {index + 1}
                </span>
              );
            })}
            <span className="parameters-modal-season">
              <b>Итого</b>
            </span>
          </div>

          <span className="parameters-modal-line" />

          <div className="parameters-modal-season__column">
            <span className="parameters-modal-season">
              <b>Время сезонов</b>
            </span>
            {appStore.turnDurationsSec.map((durationSec, index) => {
              return (
                <span key={index + ' ' + durationSec} className="parameters-modal-season parameters-modal-season__time">
                  <span>{getFormattedTimeFromSecond(durationSec)}</span>
                </span>
              );
            })}

            <span
              key="parameters-modal-season__time__total-time"
              className="parameters-modal-season parameters-modal-season__time"
            >
              <span>
                {appStore.withRandomGameEnd ? (
                  <b>
                    {minimalGameDuration} - {fullGameDuration}
                  </b>
                ) : (
                  <b>{fullGameDuration}</b>
                )}
              </span>
            </span>
          </div>
        </div>
      ) : (
        <div className="parameters-modal-realtime">
          <span className="parameters-modal-realtime_session">
            Продолжительность игрового месяца - {getFormattedTimeFromSecond(appStore.monthDuration)}
          </span>
          <span className="parameters-modal-realtime_session">
            Продолжительность сессии - {getFormattedTimeFromSecond(realtimeSessionDuration)}
          </span>
          <div className="parameters-modal-realtime_mechanics">
            {Object.values(REALTIME_MECHANICS_TITLE).map((title) => {
              return (
                <span className="parameters-modal-realtime_mechanics-item" key={title}>
                  {title} - {textFormatForMechanicRealtime(REALTIME_MECHANICS_TIME[title])}
                </span>
              );
            })}
          </div>
        </div>
      ),
    },
    {
      isVisible: () => {
        return appStore.credits.credit_mechanics;
      },
      title: 'Информация о кредитах',
      content: (
        <div>
          <div className="parameters-modal-information">
            <span>
              Максимальная сумма кредитования -
              <span className="notranslate"> {appStore.startingParameters.max_possible_credit} </span>
              <CoinIcon
                style={{ position: 'relative', top: '2px', marginLeft: '4px', height: '16px', width: '16px' }}
                alt="Монетка"
              />
            </span>
          </div>
          {appStore.credits.credit_percentages &&
            appStore.credits.credit_percentages.map((i, index) => (
              <div className="parameters-modal-information parameters-modal-information-credit__type" key={index}>
                <span>{CREDIT_TYPE_NAME[i.credit_type]}</span>
                <span className="notranslate"> - {i.percentage}%</span>
              </div>
            ))}
        </div>
      ),
    },
    {
      isVisible: () => {
        return appStore.with_ltd && appStore.receivingLTDIsAccepted;
      },
      title: 'Банковская гарантия',
      content: (
        <div>
          <div className="parameters-modal-information">
            <span>
              доступная сумма банковской гарантии для всех сделок -
              <span className="notranslate"> {appStore.longTermsDeals.max_bank_guarantee} </span>
              <CoinIcon
                style={{ position: 'relative', top: '2px', marginLeft: '4px', height: '16px', width: '16px' }}
                alt="Монетка"
              />
            </span>
          </div>
          {appStore.withChanceBankruptcy && (
            <div className="parameters-modal-information">
              <span>
                Шанс банкротства при отсутсвии банковской гарантии -
                <span className="notranslate"> {appStore.bankruptcyChanceBG} %</span>
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      isVisible: () => {
        return appStore.with_ltd && appStore.receivingLTDIsAccepted;
      },
      title: 'Информация по штрафам и расторжению',
      content: (
        <div className="parameters-modal-information">
          <span>Максимально доступные суммы -</span>
          <span className="notranslate"> {appStore.longTermsDeals.max_possible_fine}%</span>
        </div>
      ),
    },
  ];
  return (
    <div className="modal__body-actions">
      <dl className="modal__description-list">
        {Object.values(Parameters)
          .filter((key, index) => {
            return !Parameters[index].isVisible || Parameters[index].isVisible();
          })
          .map((item, index) => {
            return (
              <div key={index} className="parameters-modal__wrapper">
                <dt className="parameters-modal__title">{item.title}</dt>
                <dd className="parameters-modal__definition">{item.content}</dd>
              </div>
            );
          })}
      </dl>
    </div>
  );
}

export default observer(StartSessionParameters);
