import React from 'react';
import RootStore from '../../store';
import { CREDIT_TYPE_NOTIFICATION } from '../../store/constants';
import trainingStore from '../../store/trainingStore';
import { observer } from 'mobx-react';
import Modal from './Modal/Modal';
import TextButton from '../buttons/TextButton/TextButton';
import './EarlyRepaymentModal.scss';
import EarlyRepaymentComponent from './Credit/EarlyRepaymentComponent';

function EarlyRepaymentModal() {
  const { appStore } = RootStore;

  if (!appStore.earlyRepaymentFlag) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.earlyRepaymentFlag}
      onClose={() => appStore.hideEarlyRepayment()}
      size="middle"
      title="вы точно хотите погасить кредит досрочно?"
      customClass="early-repayment"
      shouldCloseOnOverlayClick={!trainingStore.currentStep}
    >
      <EarlyRepaymentComponent
        repaymentAmount={appStore.earlyRepaymentCredit.repaymentAmount}
        prePaymentAmount={appStore.earlyRepaymentCredit.prePaymentAmount}
      />
      <div className="modal__body-repayment__action">
        <TextButton
          color="green"
          title="погасить"
          onClick={() => appStore.creditRepay(appStore.earlyRepaymentCredit.credit_id)}
        />
        <TextButton color="blue" title="вернуться" onClick={() => appStore.hideEarlyRepayment()} />
      </div>
    </Modal>
  );
}

export default observer(EarlyRepaymentModal);
