import { makeAutoObservable } from 'mobx';
import {
  LTD_DOCTOR_REQUEST_STATUSES,
  MESSAGE_TYPE,
  CREDIT_STATUS,
  LIMITS_TYPE,
  FAKE_LTD_TEAM_TASK_DATA,
  FAKE_LTD_TEAM_TASK_STATUS,
  OPERATION_TYPE,
  AREA_POSITION,
  INTERACTION_AREA,
  TRANSFER_STATUS,
  CAR_TYPE,
  CAR_SIZE,
  CAR_ACTION_TYPE,
  DELEGATING_STATES,
  TEAM_TASK_STATUS,
  TASK_TYPES,
  TRANSFER_TYPE,
  ALLOCATION_NOTIFICATION_TYPES,
  TRANSPORTATION_STATUS,
} from './constants';
import citiesImages from '../common/citiesImages';
import _ from 'lodash';
import * as teamMode from './teamMode';
import { playerHasPrivileges, PRIVILEGES } from './teamMode';
import RootStore from './index';
import catsSort from '../common/catsSort';
import catDataForContent from '../common/catDataForContent';
import {
  CATS_TEMPLATE,
  DRAG_AREA,
  LTD_ACTIONS,
  LTD_CONTENT_TYPE,
  LTD_FINES_PAYMENT,
  LTD_STATUS,
  PLAYER_ROLE,
  SICKNESS,
  SPECIALITIES,
} from '../common/constants';
import { ROOT_PATHS } from '../common/constants';
import routes from '../routes';
import formatTeamHistory from '../common/formatTeamHistory';

export const DELEGATING_OPTIONS = {
  CEO_CAPTAIN_TEAM: [
    {
      label: 'СЕО',
      value: 'ceo',
    },
    {
      label: 'Капитан',
      value: 'captain',
    },
    {
      label: 'Команда',
      value: 'team',
    },
  ],
  CEO_CAPTAIN: [
    {
      label: 'СЕО',
      value: 'ceo',
    },
    {
      label: 'Капитан',
      value: 'captain',
    },
  ],
};

export const LIMITS_INFO = {
  [LIMITS_TYPE.GET_CREDIT]: {
    limitType: LIMITS_TYPE.GET_CREDIT,
    privileges: [PRIVILEGES.APPROVE_GET_CREDIT_BY_CEO],
    visibled: () => RootStore.appStore.credits?.credit_mechanics,
    allPrivilegesNeeded: true,
  },
  [LIMITS_TYPE.SPENDING_ON_FOOD]: {
    limitType: LIMITS_TYPE.SPENDING_ON_FOOD,
    privileges: [PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CEO],
    visibled: () => RootStore.appStore.me.role === PLAYER_ROLE.NURSERY,
    allPrivilegesNeeded: true,
  },
  [LIMITS_TYPE.SPENDING_ON_MEDICINES]: {
    limitType: LIMITS_TYPE.SPENDING_ON_MEDICINES,
    privileges: [PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CEO],
    visibled: () => RootStore.appStore.me.role === PLAYER_ROLE.NURSERY,
    allPrivilegesNeeded: true,
  },
  [LIMITS_TYPE.SPENDING_ON_HOUSE]: {
    limitType: LIMITS_TYPE.SPENDING_ON_HOUSE,
    visibled: () => RootStore.appStore.me.role === PLAYER_ROLE.NURSERY,
    privileges: [PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CEO],
    allPrivilegesNeeded: true,
  },
  [LIMITS_TYPE.SPENDING_ON_CAT_BUY]: {
    limitType: LIMITS_TYPE.SPENDING_ON_CAT_BUY,
    privileges: [PRIVILEGES.APPROVE_CATS_PURCHASE_BY_CEO, PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CEO],
    allPrivilegesNeeded: false,
  },
  [LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_BUY]: {
    limitType: LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_BUY,
    privileges: [PRIVILEGES.APPROVE_CATS_PURCHASE_BY_CEO],
    allPrivilegesNeeded: true,
  },
  [LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_SELL]: {
    limitType: LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_SELL,
    privileges: [PRIVILEGES.APPROVE_CATS_SELL_BY_CEO],
    allPrivilegesNeeded: true,
  },
};

class CorpStore {
  resetState() {
    this.areas = {};
    this.currentAreaNum = null;

    // Для транспортировки и трансферов ↓
    this.currentOperationType = null;
    this.areaNumField = {
      top: null,
      bottom: null,
    };
    this.operationContent = null;
    this.transportations = [];
    this.receivingCarsModalData = null;
    this.movingCatsModalData = null;
    this.notEnoughSeasonForTransferModalData = null;
    this.notEnoughSeasonForTransportationModalData = null;
    this.carCost = null;
    this._myCars = [];
    this.carsCount = null;
    this.transfers = [];

    this.confirmationActionWithCarData = null;

    this.corporationModal = false;
    this.budgetAllocationNotificationModal = null;
    this.budgetAllocationConfirmationModalData = null;
    this.transferDetailDataModal = null;
    this.importPaymentModalData = null;
    this.importExportNotificationModalData = null;
    this.limitModalData = { isOpen: false };
    this.teamLimits = {};
    this.currentSeasonLimitsModalFlag = {
      isOpen: false,
    };
  }

  constructor() {
    this.resetState();
    makeAutoObservable(this);
  }

  // ToDo BCC-69 добавить fill_sync для переноса из appStore заполнение store в corpStore

  // Для тестирования - спаун котов
  addCatForDev(cat) {
    RootStore.appStore.sendMessage(MESSAGE_TYPE.ADD_CAT_FOR_DEVELOP, cat);
  }

  // Получить контент для Swiper в детализации баланса ↓
  get balanceDetailContents() {
    const result = [{ title: 'Корпорация', target: 0 }];
    // captain.game_over_season + 1, т.к. нужно зафиксировать значения за сезон, в котором обанкротился игрок
    this.corporationCaptains.forEach((captain) => {
      result.push({
        title: `Город ${captain.areaNum}`,
        target: captain.areaNum,
        bankrupt: !captain.active && captain.game_over_season + 1 <= RootStore.appStore.currentBalanceSeason,
      });
    });
    return result;
  }

  // Получить все города ↓
  get allAreas() {
    return Object.values(this.areas).map((item, index) => {
      return {
        ...item,
        src: citiesImages[index],
        active: _.find(item.companies, { uuid: item.company_uuid }).active,
      };
    });
  }

  // Получить все компании + СЕО ↓
  get corpPlayers() {
    return _.concat(RootStore.appStore._players, this.getInfoFromAllAreasByProperty('companies'));
  }

  // Функция собирает информацию со всех городов по переданному свойству ↓
  get getInfoFromAllAreasByProperty() {
    return (property) => {
      const result = [];
      Object.values(this.areas).forEach((area) => {
        let _property = area[property];
        if (_property) {
          if (!_.isArray(_property)) {
            _property = [_property];
          }
          result.push(..._property);
        } else {
          alert('Нет такого свойства в area');
          return null;
        }
      });
      return result;
    };
  }

  get blockedCompaniesOfAllAreas() {
    const result = {};
    Object.entries(this.areas).forEach(([areaNum, area]) => {
      result[areaNum] = area.blockedCompanies;
    });
    return result;
  }

  get blockedByCompaniesOfAllAreas() {
    const result = {};
    Object.entries(this.areas).forEach(([areaNum, area]) => {
      result[areaNum] = area.blockedByCompanies;
    });
    return result;
  }

  // Получить areaNum активных городов ↓
  get allActiveAreaNums() {
    return this.allAreas
      .filter(
        (area) =>
          area.active && area.company_players.find((p) => p.is_timed_out === false && p.specialty !== SPECIALITIES.CEO),
      )
      .map((area) => area.area_num);
  }

  // Установить номер города ↓
  setCurrentAreaNum(areaNum) {
    this.currentAreaNum = !areaNum ? areaNum : parseInt(areaNum);
    if (RootStore.router.currentRoute?.rootPath === ROOT_PATHS.CITY) {
      RootStore.goTo(routes.city, { areaNum: this.currentAreaNum });
    }
  }

  // Проверка того, что игрок явялется доктором в каком-либо из наших городов
  get playerIsDoctorOfSomeOurArea() {
    return (player_id) => {
      for (const area of Object.values(this.areas)) {
        const player = area.company_players.find((player) => player.player_id === player_id);
        if (player && teamMode.playerHasSpecialities(player?.privileges, [SPECIALITIES.DOCTOR])) {
          return true;
        }
      }
    };
  }

  addExaminedCatsIds(area, ids) {
    if (!_.isArray(ids)) {
      ids = [ids];
    }
    this.areas[area].examined_cats_ids.push(...ids);
    this.areas[area].examined_cats_ids = _.uniq(this.areas[area].examined_cats_ids);
  }

  // Получить все компании СЕО, status которых === active ↓
  get myActiveCompanies() {
    return Object.values(this.areas)
      .map((area) => _.find(area.companies, { uuid: area.company_uuid }))
      .filter((company) => company.active);
  }

  get creditsOfAllAreas() {
    const result = [];
    Object.values(this.areas).forEach((area) => {
      result.push(...area.credits.credits_info);
    });
    return result;
  }

  // Получить компанию по переданному area_num ↓
  get currentCompanyByAreaNum() {
    return (areaNum) => {
      return _.find(this.myActiveCompanies, { areaNum: areaNum });
    };
  }

  // Получить тело для конкретного города ↓
  get currentArea() {
    return this.areas[this.currentAreaNum];
  }

  // Получить всех капитанов ↓
  get allCaptainsAreas() {
    return _.flatten(Object.values(this.areas).map((area) => area.companies));
  }

  // Получить uuid компании конкретного города, если есть this.currentAreaNum ↓
  get companyUuidCurrentArea() {
    return this.currentArea?.company_uuid || '';
  }

  // Получить все компании конкретного города, если есть this.currentAreaNum ↓
  get companiesCurrentArea() {
    return this.currentArea?.companies || [];
  }

  // Получить defaultPrices компании другого СЕО по UUID-сео ↓
  get catsCompanyForLTDByUUID() {
    return (uuid, areaNum) => {
      const result = _.cloneDeep(CATS_TEMPLATE);
      const cats =
        _.find(this.allActiveCaptainsOfCEO(uuid), {
          areaNum: +areaNum,
        })?.default_prices || [];

      cats.forEach((cat) => {
        const item = _.find(result, { gender: cat.gender, color: cat.color });
        item.buy_price = cat.buy_price;
        item.sell_price = cat.sell_price;
      });
      return result;
    };
  }

  // Получить текущий cityQuota, если есть this.currentAreaNum ↓
  get currentAreaCityQuota() {
    return this.currentArea?.cityQuota || [];
  }

  // Получить cityQuota по areaNum ↓
  get cityQuotaByAreaNum() {
    return (areaNum) => {
      const result = _.cloneDeep(CATS_TEMPLATE);
      this.areas[areaNum].cityQuota.forEach((cat) => {
        const item = _.find(result, { gender: cat.gender, color: cat.color });
        item.buy_price = cat.buy_price;
        item.sell_price = cat.sell_price;
        item.quantity = cat.quantity;
        item.count = cat.quantity;
      });
      return result;
    };
  }

  // Получить капитана конкретного города ↓
  get captainCurrentArea() {
    return _.find(this.companiesCurrentArea, { uuid: this.companyUuidCurrentArea });
  }

  // Получить все питомники конкретного города, если есть this.currentAreaNum ↓
  get nurseriesCurrentArea() {
    return _.filter(this.currentArea?.companies, { role: PLAYER_ROLE.NURSERY }) || [];
  }

  // Получить все магазины конкретного города, если есть this.currentAreaNum ↓
  get shopsCurrentArea() {
    return _.filter(this.currentArea?.companies, { role: PLAYER_ROLE.SHOP }) || [];
  }

  get playerBySpecialityAndAreaNum() {
    return (speciality, area_num) => {
      for (const player_info of this.areas[area_num].company_players) {
        if (player_info.specialty === speciality) {
          return player_info;
        }
      }
    };
  }

  // ОПЕРАЦИИ ↓
  // Установить текущий тип операции и текушие areaNum зон ↓
  setCurrentTypeOperation(type) {
    this.currentOperationType = type;
    if (!Object.keys(this.areas).length) {
      RootStore.appStore.addToAfterTeamSynchronize(() => {
        if (Object.keys(this.areas).length === RootStore.appStore.area_quantity) {
          this.setDefaultAreaNumField();
        }
      });
    } else {
      this.setDefaultAreaNumField();
    }
  }

  // Установить для зон default areaNum в зависимости от state field ↓
  setDefaultAreaNumField() {
    if (
      !this.currentOperationType ||
      (this.currentOperationType === OPERATION_TYPE.TRANSPORTATION && this.myActiveCompanies.length < 2) ||
      (this.currentOperationType === OPERATION_TYPE.TRANSFER && !this.myActiveCompanies.length)
    ) {
      this.areaNumField = { top: null, bottom: null };
      return;
    }
    if (this.currentOperationType === OPERATION_TYPE.TRANSPORTATION) {
      this.areaNumField = { top: this.myActiveCompanies[0].areaNum, bottom: this.myActiveCompanies[1].areaNum };
    } else {
      this.areaNumField = { top: null, bottom: this.myActiveCompanies[0].areaNum };
    }
  }

  // Получить текущие areaNum для полей ↓
  get currentAreaNumField() {
    return this.areaNumField;
  }

  // Изменить для зон default areaNum ↓
  changeAreaNumField(position, areaNum) {
    this.currentAreaNumField[position] = areaNum;
  }

  get currentAreaGroupedHouses() {
    if (this.currentAreaNum) {
      const result = RootStore.appStore.groupedHouses;
      return result[this.currentAreaNum];
    } else {
      return [];
    }
  }

  // Добавить котят в зону операции и сформировать this.operationContent ↓
  addCatToOperation(cat, from, to) {
    let catIsSicknessOrHungryModalData;
    if (cat.hasOwnProperty('sickness') && cat.sickness !== SICKNESS.NONE) {
      if (from === DRAG_AREA.COMPANY_ZONE && to === DRAG_AREA.TRANSFER_ZONE) {
        catIsSicknessOrHungryModalData = { isOpen: true, isExport: true };
      } else if (to === DRAG_AREA.TRANSPORTATION_ZONE) {
        catIsSicknessOrHungryModalData = { isOpen: true, isTransportation: true };
      }
    } else if (cat.hungry) {
      if (from === DRAG_AREA.COMPANY_ZONE && to === DRAG_AREA.TRANSFER_ZONE) {
        catIsSicknessOrHungryModalData = { isOpen: true, isExport: true, isHungry: true };
      } else if (to === DRAG_AREA.TRANSPORTATION_ZONE) {
        catIsSicknessOrHungryModalData = { isOpen: true, isTransportation: true, isHungry: true };
      }
    }
    if (catIsSicknessOrHungryModalData) {
      RootStore.appStore.openCatIsSicknessOrHungryModal(catIsSicknessOrHungryModalData);
      return;
    }
    let toField;
    let fromField;
    let targetAreaNum;
    let positionTarget;
    const currentCompany = (position) => {
      return this.currentCompanyByAreaNum(this.currentAreaNumField[position]);
    };
    if (to === DRAG_AREA.TRANSPORTATION_ZONE) {
      if (from === DRAG_AREA.TRANSPORTATION_TOP) {
        toField = currentCompany(AREA_POSITION.BOTTOM).uuid;
        fromField = currentCompany(AREA_POSITION.TOP).uuid;
        targetAreaNum = currentCompany(AREA_POSITION.BOTTOM).areaNum;
        positionTarget = AREA_POSITION.BOTTOM;
      } else {
        toField = currentCompany(AREA_POSITION.TOP).uuid;
        fromField = currentCompany(AREA_POSITION.BOTTOM).uuid;
        targetAreaNum = currentCompany(AREA_POSITION.TOP).areaNum;
        positionTarget = AREA_POSITION.TOP;
      }
    } else {
      if (from === DRAG_AREA.IMPORT_EXPORT_ZONE) {
        toField = currentCompany(AREA_POSITION.BOTTOM).uuid;
        fromField = null;
        targetAreaNum = currentCompany(AREA_POSITION.BOTTOM).areaNum;
        positionTarget = AREA_POSITION.BOTTOM;
      } else {
        toField = null;
        fromField = currentCompany(AREA_POSITION.BOTTOM).uuid;
        targetAreaNum = currentCompany(AREA_POSITION.BOTTOM).areaNum;
        positionTarget = AREA_POSITION.TOP;
      }
    }

    if (!this.operationContent) {
      if (from !== DRAG_AREA.IMPORT_EXPORT_ZONE && !catDataForContent(cat)?.detail) {
        return null;
      } else {
        this.operationContent = {
          type: this.currentOperationType,
          contents: [],
          to: toField,
          from: fromField,
          targetAreaNum: targetAreaNum,
          positionTarget: positionTarget,
        };
      }
    }
    const content = _.find(this.operationContent.contents, { gender: cat.gender, color: cat.color }) || null;
    let price;
    if (this.currentOperationType === OPERATION_TYPE.TRANSFER) {
      const currentCat = _.find(this.sortedImportExportMarket, { gender: cat.gender, color: cat.color });
      price = this.operationContent.to ? currentCat.sell_price : currentCat.buy_price;
    }
    const catData = catDataForContent(cat, content, price);
    if (from !== DRAG_AREA.IMPORT_EXPORT_ZONE && !catData?.detail) {
      RootStore.appStore.notEnoughCatsModalOpen();
      return;
    }
    const catGroupedDetail = this.groupedOperationCats[0]?.contents.find((cat) => cat.detail);
    if (catData) {
      if (catData.detail && catGroupedDetail) {
        this.operationContent.contents.push(catData);
      } else if (!catData.detail && !catGroupedDetail) {
        this.operationContent.contents.push(catData);
      } else if (!catData.detail && catGroupedDetail) {
        return null;
      } else if (catData.detail && this.groupedOperationCats.length === 0) {
        this.operationContent.contents.push(catData);
      }
    }
  }

  // Группировка котят для зоны текущей операции в <OperationField /> ↓
  get groupedOperationCats() {
    if (!this.operationContent?.contents) {
      return [];
    }
    const [result] = RootStore.appStore.groupCats(this.operationContent);
    result.sort(catsSort);
    return result;
  }

  // Получить количетсво и цены котят для зоны текущей операции ↓
  get detailInfoCatsForOperation() {
    let count = 0;
    let price = 0;
    if (!this.operationContent) {
      return { count: count, price: price };
    }
    this.groupedOperationCats.forEach((cat) => {
      count += cat.count;
      price += cat.count * cat.price;
    });
    return { count: count, price: price };
  }

  // Удалить котят из зоны текущей операции (транспортировка/трансфер) ↓
  removeCatFromOperationField(cat) {
    if (!this.operationContent) {
      console.error('Что-то пошло не так в removeCatFromOperationField', this.operationContent);
      return;
    }

    if (!cat.detail) {
      cat.count--;
    }
    if (cat.detail || !cat.count) {
      this.operationContent.contents.splice(this.operationContent.contents.indexOf(cat), 1);
    }

    if (!this.operationContent.contents.length) {
      this.clearContentOperationField();
    }
  }

  removeCatsFromOperationField(cat) {
    this.operationContent.contents = this.operationContent.contents.filter(
      (item) => item.color !== cat.color || item.gender !== cat.gender,
    );

    if (!this.operationContent.contents.length) {
      this.clearContentOperationField();
    }
  }

  get headerChangeAreaSliderIsVisible() {
    return (
      RootStore.appStore.meIsCEO && this.currentAreaNum && RootStore.router.currentRoute.rootPath === ROOT_PATHS.CITY
    );
  }

  // Очистить тело для зоны текущей операции (транспортировка/трансфер) ↓
  clearContentOperationField() {
    // Если нужно запоминать выставленных котов, обработать по this.currentOperationType
    this.operationContent = null;
  }

  // Получить с какой можно областью сейчас взаимодействовать для зоны текущей операции ↓
  get nurseriesCEO() {
    const role = PLAYER_ROLE.NURSERY;
    return _.filter(RootStore.appStore.players, (player) => player.role === role);
  }

  get shopsCEO() {
    const role = PLAYER_ROLE.SHOP;
    return _.filter(RootStore.appStore.players, (player) => player.role === role);
  }

  get anotherRoleCEO() {
    return RootStore.appStore.players.filter((CEO) => CEO.role !== RootStore.appStore.me?.role);
  }

  get allActiveCaptainsOfCEO() {
    return (ceo_uuid) => {
      const CEO = RootStore.appStore.playerByUuid(ceo_uuid);
      return this.allCaptainsAreas.filter((captain) => captain.name === CEO.name && captain.active);
    };
  }

  get allCaptainsOfCEO() {
    return (ceo_uuid) => {
      const CEO = RootStore.appStore.playerByUuid(ceo_uuid);
      return this.allCaptainsAreas.filter((captain) => captain.name === CEO.name);
    };
  }

  get allCEOWithActiveCompanyInArea() {
    return (area_num, onlyAnotherRole = false) => {
      let players;
      if (onlyAnotherRole) {
        players = this.anotherRoleCEO;
      } else {
        players = RootStore.appStore.players;
      }
      return players.filter((CEO) => this.allActiveAreaNumsOfCEO(CEO.uuid).find((_area_num) => area_num == _area_num));
    };
  }

  // Получить с какой можно областью сейчас взаимодействовать ↓
  get interactionAreaAvailability() {
    if (
      this.currentOperationType === OPERATION_TYPE.TRANSPORTATION &&
      this.currentAreaNumField[AREA_POSITION.TOP] === this.currentAreaNumField[AREA_POSITION.BOTTOM]
    ) {
      return { top: INTERACTION_AREA.BLOCKED, bottom: INTERACTION_AREA.BLOCKED };
    }
    if (!this.operationContent) {
      return { top: INTERACTION_AREA.AVAILABLE, bottom: INTERACTION_AREA.AVAILABLE };
    }
    if (this.currentOperationType === OPERATION_TYPE.TRANSPORTATION) {
      const companyUuidOnTop = this.currentCompanyByAreaNum(this.currentAreaNumField[AREA_POSITION.TOP]).uuid;
      const senderCompanyUuid = this.operationContent.from;
      return companyUuidOnTop === senderCompanyUuid
        ? { top: INTERACTION_AREA.AVAILABLE, bottom: INTERACTION_AREA.BLOCKED }
        : { top: INTERACTION_AREA.BLOCKED, bottom: INTERACTION_AREA.AVAILABLE };
    } else {
      return {
        top: this.operationContent.to ? INTERACTION_AREA.AVAILABLE : INTERACTION_AREA.BLOCKED,
        bottom: this.operationContent.to ? INTERACTION_AREA.BLOCKED : INTERACTION_AREA.AVAILABLE,
      };
    }
  }

  // Сделать отправку котят по текущей операции (транспортировка/трансфер) ↓
  sendCurrentOperation() {
    if (!this.operationContent || !this.operationContent.contents.length) {
      console.error('Что-то пошло не так, в sendCurrentOperation', this.operationContent);
      return;
    }
    const operationContent = this.operationContent;
    const currentSeason = RootStore.appStore.currentSeason + 1;
    const seasonsCount = RootStore.appStore.turnDurationsSec.length;

    if (operationContent.type === OPERATION_TYPE.TRANSPORTATION) {
      const callback = () => {
        RootStore.appStore.sendMessage(MESSAGE_TYPE.MOVING_CATS_BETWEEN_CITIES, {
          sender_city_captain_id: operationContent.from,
          recipient_city_captain_id: operationContent.to,
          cats: operationContent.contents.map((cat) => {
            return cat.detail.cat_id;
          }),
        });
        this.clearContentOperationField();
      };
      const sellCatFromBigWindowModalShowed = RootStore.appStore.showSellCatFromHomeBigWindowModal(
        operationContent.contents,
        callback,
        DRAG_AREA.TRANSPORTATION_ZONE,
      );
      if (!sellCatFromBigWindowModalShowed) {
        callback();
      }
      return;
    } else {
      if (operationContent.to) {
        let totalPrice = 0;
        operationContent.contents.forEach((content) => {
          totalPrice += content.count * content.price;
        });
        /**
         * totalPrice = count * price
         * totalPrice = 30%, т.к. необходимо проверить сумму аванса
         */
        if (!RootStore.appStore.checkBalance(totalPrice * 0.3)) {
          RootStore.appStore.notEnoughBalanceModalOpen();
          return;
        }

        // {BCC-980} - убрано в комментарий в связи с тем, что убираем ограничение на \
        // количество оставшихся сезонов. Оставил на будущее, вдруг понадобиться вернуть проверку.
        // Импорт нельзя взять импорт позже, чем за 2 сезона до конца сессии
        // if (seasonsCount - currentSeason < 2) {
        //   this.openNotEnoughSeasonForTransferModal(TRANSFER_TYPE.IMPORT);
        //   this.clearContentOperationField();
        //   return;
        // }
        RootStore.appStore.sendMessage(MESSAGE_TYPE.IMPORT_REQUEST, {
          to: operationContent.to,
          abstract_contents: operationContent.contents.map((cat) => {
            return {
              gender: cat.gender,
              color: cat.color,
              count: cat.count,
              price: cat.price,
            };
          }),
        });
      } else {
        // {BCC-980} - убрано в комментарий в связи с тем, что убираем ограничение на \
        // количество оставшихся сезонов. Оставил на будущее, вдруг понадобиться вернуть проверку.
        // Экспорт нельзя взять позже, чем за 3 сезона до конца сессии
        // if (seasonsCount - currentSeason < 3) {
        //   this.openNotEnoughSeasonForTransferModal(TRANSFER_TYPE.EXPORT);
        //   this.clearContentOperationField();
        //   return;
        // }
        const callback = () => {
          RootStore.appStore.sendMessage(MESSAGE_TYPE.EXPORT_REQUEST, {
            city_from: operationContent.from,
            exact_cats: operationContent.contents.map((cat) => {
              return cat.detail.cat_id;
            }),
          });
          this.clearContentOperationField();
        };
        const sellCatFromBigWindowModalShowed = RootStore.appStore.showSellCatFromHomeBigWindowModal(
          operationContent.contents,
          callback,
          DRAG_AREA.TRANSFER_ZONE,
        );
        if (!sellCatFromBigWindowModalShowed) {
          callback();
        }
        return;
      }
    }
    this.clearContentOperationField();
  }

  // ТРАНСФЕР ↓
  // Получить котят для рынка ↓
  get sortedImportExportMarket() {
    const result = _.cloneDeep(CATS_TEMPLATE);
    result.map((cat) => {
      const catFromCity = _.find(RootStore.appStore.cityQuota, { gender: cat.gender, color: cat.color });
      cat.buy_price = catFromCity.buy_price;
      cat.sell_price = catFromCity.sell_price;
    });
    return result;
  }

  // Получить текущие трансферы по переданному типу - import\export  ↓
  get transferContentByType() {
    return (type, buyerOrSeller = null) => {
      /**
       * Остаточная стоимость = 70% от суммы трансфера
       */
      let result = [];
      let companyUuid;
      if (buyerOrSeller) {
        companyUuid = buyerOrSeller;
      } else if (this.currentCompanyByAreaNum(this.currentAreaNumField[AREA_POSITION.BOTTOM])) {
        companyUuid = this.currentCompanyByAreaNum(this.currentAreaNumField[AREA_POSITION.BOTTOM]).uuid;
      } else {
        return [];
      }
      this.transfers
        .filter((transfer) => {
          if (type === TRANSFER_TYPE.IMPORT) {
            return transfer.buyer === companyUuid && transfer.status !== TRANSFER_STATUS.PAID;
          } else {
            return transfer.seller === companyUuid;
          }
        })
        .forEach((item) => {
          let count = 0;
          let price = 0;
          let totalPrice = 0;
          item.contents.forEach((content) => {
            count += content.count;
            price += content.price;
            totalPrice += content.count * content.price;
          });
          /**
           * Аванс = округление в большую сторону(сумма котов * 30%)
           * Постоплата = сумма котов - сумма аванса
           */
          const postpaid = Math.ceil(totalPrice * 0.3);
          const residualValue = totalPrice - postpaid;
          result.push({
            transfer_id: item.lot_id,
            area_num:
              parseInt(this.areaNumByCaptainId(type === TRANSFER_TYPE.IMPORT ? item.buyer : item.seller)) || null,
            type: type,
            count: count,
            contents: item.contents,
            season: item.delivery_season + 1,
            residual_value: residualValue,
            status: item.status,
          });
        });
      return result;
    };
  }

  importExportDataToTeamTaskFormatAdapter(importExportData) {
    if (!importExportData) {
      return null;
    }
    const result = {};

    const isImport = !!importExportData.buyer;
    let taskInfo = this.transferContentByType(
      isImport ? TRANSFER_TYPE.IMPORT : TRANSFER_TYPE.EXPORT,
      isImport ? importExportData.buyer : importExportData.seller,
    ).find((transferInfo) => transferInfo.transfer_id === importExportData.lot_id);
    if (!taskInfo) {
      let count = 0;
      let fullPrice = 0;
      importExportData.contents.forEach((catInfo) => {
        count += catInfo.count;
        fullPrice += catInfo.price;
      });
      const prepaidPrice = Math.ceil(fullPrice * 0.3);
      const postPaymentPrice = fullPrice - prepaidPrice;
      taskInfo = {
        count,
        prepaid_price: fullPrice,
        post_payment_price: postPaymentPrice,
      };
    }
    let status;
    if (
      importExportData.status === TRANSFER_STATUS.AWAITING_PAYMENT ||
      importExportData.status === TRANSFER_STATUS.AWAITING_DELIVERY
    ) {
      status = TEAM_TASK_STATUS.IN_PROGRESS;
    } else if (importExportData.status === TRANSFER_STATUS.PAID) {
      status = TEAM_TASK_STATUS.CONFIRMED;
    }

    result.content = importExportData.contents;
    if (isImport) {
      result.import_status = importExportData.status;
      result.buyer = importExportData.buyer;
      result.type = TASK_TYPES.FAKE_IMPORT_TASK;
    } else {
      result.export_status = importExportData.status;
      result.seller = importExportData.seller;
      result.type = TASK_TYPES.FAKE_EXPORT_TASK;
    }
    result.stage = SPECIALITIES.CEO;
    result.delivery_season = importExportData.delivery_season;
    result.status = status;
    result.lot_id = importExportData.lot_id;
    result.prepaid_price = taskInfo.prepaid_price;
    result.post_payment_price = taskInfo.residual_value || taskInfo.post_payment_price;
    result.season = importExportData.delivery_season;
    result.count = taskInfo.count;
    result.server_time = importExportData.server_time;
    result.specialty_by = SPECIALITIES.CEO;
    result.visible_to = [SPECIALITIES.CEO];
    result.visas = { [SPECIALITIES.CEO]: true };
    return result;
  }

  importDataToTeamTaskAdapter(importExportData) {
    if (!importExportData || !RootStore.appStore.meIsCEO) {
      return null;
    }
    if (RootStore.appStore.isCorpMode && RootStore.appStore.meIsCEO) {
      if (!_.isArray(importExportData)) {
        importExportData = [importExportData];
      }

      const importData = importExportData.filter((data) => !data.seller);
      RootStore.appStore.tasks = RootStore.appStore.tasks.filter((task) => {
        if (task.hasOwnProperty('import_status')) {
          return task.delivery_season >= RootStore.appStore.currentSeason;
        } else {
          return true;
        }
      });
      importData.forEach((data) => {
        if (!_.find(this.corporationCaptains, { player_id: data.buyer })?.active) {
          return;
        }
        if (data.delivery_season !== RootStore.appStore.currentSeason) {
          return;
        }
        const task = RootStore.appStore.tasks.find((task) => task.lot_id === data.lot_id && task.buyer === data.buyer);
        if (data.status !== TRANSFER_STATUS.AWAITING_PAYMENT) {
          _.remove(RootStore.appStore.tasks, { lot_id: data.lot_id, buyer: data.buyer });
          return;
        }
        const taskInfo = this.importExportDataToTeamTaskFormatAdapter(data);
        if (task) {
          task.content = taskInfo.contents;
          task.stage = SPECIALITIES.CEO;
          task.delivery_season = taskInfo.delivery_season;
          task.status = taskInfo.status;
          task.price = taskInfo.residual_value;
          task.season = taskInfo.season;
          task.count = taskInfo.count;
          task.type = taskInfo.type;
          task.import_status = taskInfo.status;
          task.visible_to = taskInfo.visible;
          return;
        }
        RootStore.appStore.tasks.push(taskInfo);
      });
    }
  }

  budgetAllocationToTeamHistoryFormatAdapter(data) {
    if (!data) {
      return null;
    }
    data = _.cloneDeep(data);
    const formattedTask = {
      status: TEAM_TASK_STATUS.CONFIRMED,
      type: TASK_TYPES.FAKE_BUDGET_ALLOCATION_TASK,
      stage: SPECIALITIES.CEO,
      server_time: data.server_time,
      specialty_by: SPECIALITIES.CEO,
      visible_to: [SPECIALITIES.CEO],
      visas: { [SPECIALITIES.CEO]: true },
      delta: data.message_owner === RootStore.appStore.ceo_uuid ? -data.records.delta : data.records.delta,
      task_id: `${data.records.reason}_${data.message_owner}_${data.server_time}`,
      message_owner: data.message_owner === RootStore.appStore.ceo_uuid ? data.records.target : data.message_owner,
    };

    return {
      server_time: formattedTask.server_time,
      task_update: formattedTask,
    };
  }

  creditUpdateToTeamHistoryAdapter(data) {
    if (!data) {
      return null;
    }

    let taskType;
    if (data.credit_info.status === CREDIT_STATUS.ACTIVE) {
      taskType = TASK_TYPES.GET_CREDIT;
    } else {
      taskType = TASK_TYPES.CREDIT_REPAY;
    }

    const formattedTask = {
      type: taskType,
      stage: SPECIALITIES.CEO,
      task_id: `credit_${taskType}_${data.credit_info.credit_id}`,
      season: RootStore.appStore.currentSeason,
      server_time: data.server_time,
      specialty_by: SPECIALITIES.CEO,
      visible_to: [SPECIALITIES.CEO],
      visas: { [SPECIALITIES.CEO]: true },
      company_id: RootStore.appStore.ceo_uuid,
      status: TEAM_TASK_STATUS.CONFIRMED,
      created_by: RootStore.appStore.ceo_uuid,
      updated_by: RootStore.appStore.ceo_uuid,
      created: data.server_time,
      updated: data.server_time,
      content: {
        money_quantity: data.credit_info.money_quantity,
        credit_type: data.credit_info.credit_type,
        season_quantity: data.credit_info.credit_bodies.length,
        credit_id: data.credit_info.credit_id,
      },
    };

    return {
      server_time: formattedTask.server_time,
      task_update: formattedTask,
    };
  }

  /**
   * Возвращает статус долгосрочной сделки для конкретного LTD_UPDATE
   * @param LTDUpdate - обновление по долгосрочной сделке
   * @param content - контент LTD, для которого выдается статус
   * @param currentDealLTDUpdates - LTD_UPDATE'ы для долгосрочной сделки, по которой пришел LTD_UPDATE
   * @param currentLTDUpdatesOfCurrentContentType - LTD_UPDATE'ы для долгосрочной сделки, по которой пришел LTD_UPDATE и для контента, для которого выдается статус
   * @param isFromSynchronize - флаг того, что выдача статуса вызывается из синхронайза
   */
  get fakeLTDTaskStatus() {
    return (LTDUpdate, content, currentDealLTDUpdates, currentLTDUpdatesOfCurrentContentType, isFromSynchronize) => {
      const ltd = _.cloneDeep(LTDUpdate);

      const contentType = RootStore.appStore.typeOfLTDContent(content);
      // Предыдущее действие в долгосрочной сделке по конкретному типу контента
      const previousLTDActionForContent =
        currentLTDUpdatesOfCurrentContentType &&
        currentLTDUpdatesOfCurrentContentType[currentLTDActionByContentHistoryIndex - 1];
      const contentOfPreviousLTDActionForContent =
        previousLTDActionForContent &&
        RootStore.appStore.LTDContentByType(previousLTDActionForContent.contents, contentType);

      // Проверка на то, что был совершен осмотр доктором
      if (contentOfPreviousLTDActionForContent) {
        const oldContentDoctorRequestStatus = RootStore.appStore.getOurDoctorInfoInDoctorRequests(
          contentOfPreviousLTDActionForContent.doctor_requests,
        )?.status;
        const newContentDoctorRequestStatus = RootStore.appStore.getOurDoctorInfoInDoctorRequests(
          content.doctor_requests,
        )?.status;

        if (
          oldContentDoctorRequestStatus !== newContentDoctorRequestStatus &&
          [LTD_DOCTOR_REQUEST_STATUSES.APPROVED, LTD_DOCTOR_REQUEST_STATUSES.DECLINED].includes(
            newContentDoctorRequestStatus,
          )
        ) {
          return FAKE_LTD_TEAM_TASK_STATUS.EXAMINED_BY_DOCTOR;
        }
      }

      const status = Object.keys(FAKE_LTD_TEAM_TASK_DATA).find((ltd_status) => {
        return FAKE_LTD_TEAM_TASK_DATA[ltd_status].validate(ltd, content);
      });

      // Получение индекса для действия по конкретному типу контента в истории по данному типа контента
      let currentLTDActionByContentHistoryIndex;
      // Получение индекса для действия во всей истории конкретной долгосрочной сделки
      let currentLTDActionHistoryIndex;
      if (currentLTDUpdatesOfCurrentContentType) {
        currentLTDUpdatesOfCurrentContentType.some((ltd, index) => {
          const _content = RootStore.appStore.LTDContentByType(ltd.contents, contentType);
          if (_.isEqual(_content, content)) {
            currentLTDActionByContentHistoryIndex = index;
            return true;
          }
          return false;
        });

        currentDealLTDUpdates.some((ltd, index) => {
          const _content = RootStore.appStore.LTDContentByType(ltd.contents, contentType);
          if (_.isEqual(_content, content)) {
            currentLTDActionHistoryIndex = index;
            return true;
          }
          return false;
        });
      }

      // Предыдущее действие по конкретной LTD(даже если действие относится к другому типу контента)
      let previousLTDAction;
      if (isFromSynchronize) {
        if (currentDealLTDUpdates?.length) {
          previousLTDAction = currentDealLTDUpdates[currentLTDActionHistoryIndex - 1];
        }
      } else {
        previousLTDAction = RootStore.appStore.sortedActionsLTD && RootStore.appStore.ltdById(ltd.deal_id)?.ltd_info;
      }

      // Проверка на то, что мы являемся последним согласующим в сделке
      if (status === FAKE_LTD_TEAM_TASK_STATUS.AGREED) {
        if (previousLTDAction) {
          const meIsPartnerAndInitiatorIsLastPersonToAgreeLTD =
            ltd.initiator !== RootStore.appStore.ourCeoUuid &&
            !previousLTDAction.initiator_confirmed &&
            ltd.initiator_confirmed;
          const meIsInitiatorAndPartnerIsLastPersonToAgreeLTD =
            ltd.initiator === RootStore.appStore.ourCeoUuid &&
            previousLTDAction.partner_confirmed &&
            ltd.partner_confirmed;
          if (meIsPartnerAndInitiatorIsLastPersonToAgreeLTD || meIsInitiatorAndPartnerIsLastPersonToAgreeLTD) {
            return null;
          }
        }
      }

      // Проверка на то, что оплата и получение оплаты по штрафу были совершены
      const liabilityIsClosed = true;
      const onlyPretension = false;
      const onlyFine = true;
      if (
        status === FAKE_LTD_TEAM_TASK_STATUS.FINE &&
        !RootStore.appStore.LTDContentHasLiability(content, liabilityIsClosed, onlyPretension, onlyFine)
      ) {
        return null;
      }
      return status;
    };
  }

  LTDUpdateToTeamHistoryFormatAdapter(data, content, status) {
    if (!data) {
      return null;
    }

    const ltd = _.cloneDeep(data);
    const abstractCats = this.fakeLTDTaskAbstractCats({ ltd_contents: data.contents });
    const catsTotalPrice = abstractCats.reduce((acc, cat) => acc + cat[0].totalPrice, 0);
    const catsTotalCount = abstractCats.reduce((acc, cat) => acc + cat[0].totalCount, 0);

    const content_type = RootStore.appStore.typeOfLTDContent(content);
    const ltd_type = ltd.contents.length === 2 ? LTD_CONTENT_TYPE.BUY_SELL : content_type;

    const lastCompletedLTDAction = RootStore.appStore.lastCompletedLTDAction(content.actions);
    const lastCompletedLTDActionType = lastCompletedLTDAction?.action_type;

    const partner_captain = RootStore.appStore.partnerCaptainFromLTDContent(content);

    const areaNum = partner_captain?.areaNum;

    const cats = RootStore.appStore.LTDCatsFromContent(content, false);
    const catsCount = cats.reduce((acc, cat) => {
      return acc + (cat.count || cat.needCount);
    }, 0);

    const mainPeopleSpeciality = RootStore.appStore.isCorpMode ? SPECIALITIES.CEO : SPECIALITIES.CAPTAIN;

    const formattedTask = {
      content: {
        contents: cats,
      },
      count: catsCount,
      doctor_requests: content?.doctor_requests,
      ltd_actions: content?.actions,
      partner: ltd.partner,
      partner_captain,
      stage: mainPeopleSpeciality,
      status,
      task_id: `ltd_${ltd.deal_id}`,
      deal_id: ltd.deal_id,
      type: TASK_TYPES.FAKE_LTD_ACTION_TASK,
      content_type,
      ltd_type,
      specialty_by: mainPeopleSpeciality,
      visible_to: [mainPeopleSpeciality],
      areaNum,
      lastCompletedLTDActionType,
      two_way_termination: data.two_way_termination,
      low_quality_liability: data.low_quality_liability,
      overdue_delivery_liability: data.overdue_delivery_liability,
      overdue_payment_liability: data.overdue_payment_liability,
      prepaid: content.prepaid,
      payment_before_shipment: content.payment_before_shipment,
      payment_after_shipment: content.payment_after_shipment,
      server_time: data.server_time,
      ltd_contents: data.contents,
      originalContent: content,
      catsTotalPrice,
      catsTotalCount,
      originalLTDUpdate: data,
      visas: { [mainPeopleSpeciality]: true },
    };

    return {
      server_time: formattedTask.server_time,
      task_update: formattedTask,
    };
  }

  get fakeLTDActionAddingToTeamHistoryIsNeeded() {
    return (LTD, status, lastCompletedLTDAction) => {
      const meIsNotMainPeopleAndIsNotExaminedByDoctorAction = (() => {
        const meIsMainPeople = RootStore.appStore.isCorpMode
          ? RootStore.appStore.meIsCEO
          : RootStore.appStore.hasSpecialities(SPECIALITIES.CAPTAIN);
        return !meIsMainPeople && status !== FAKE_LTD_TEAM_TASK_STATUS.EXAMINED_BY_DOCTOR;
      })();

      const IAmNotLastCompletedActionExecutor =
        status === FAKE_LTD_TEAM_TASK_STATUS.ACTION_COMPLETED &&
        lastCompletedLTDAction &&
        lastCompletedLTDAction.executor !== RootStore.appStore.ourCeoUuid;

      const LTDIsTerminatingAndHasFine =
        LTD.status === LTD_STATUS.TERMINATING &&
        LTD.contents.some((content) => {
          const fineIsClosed = false;
          const onlyPretension = false;
          const onlyFine = true;
          return RootStore.appStore.LTDContentHasLiability(content, fineIsClosed, onlyPretension, onlyFine);
        });

      if (!status) {
        return false;
      }

      if (meIsNotMainPeopleAndIsNotExaminedByDoctorAction) {
        return false;
      }

      if (IAmNotLastCompletedActionExecutor) {
        return false;
      }

      if (LTDIsTerminatingAndHasFine) {
        return false;
      }

      return true;
    };
  }

  /**
   * Проверка на то, что переданный LTDUpdate нужно добавлять в историю
   * @param LTDUpdate - обновление по долгосрочной сделке
   * @param contentType - тип контента, для которого и проводится проверка
   * @param status - статус данного LTDUpdate
   * @param isCEOOrCaptainHistory - флаг на то, что добавление происходит в историю СЕО или капитана
   */
  get addingLTDUpdateToTeamHistoryIsNeeded() {
    return (LTDUpdate, contentType, status, isCEOOrCaptainHistory) => {
      if (!isCEOOrCaptainHistory && status && status !== FAKE_LTD_TEAM_TASK_STATUS.EXAMINED_BY_DOCTOR) {
        return false;
      }

      const content = RootStore.appStore.LTDContentByType(LTDUpdate.contents, contentType);
      const lastCompletedLTDAction = RootStore.appStore.lastCompletedLTDAction(content.actions);
      if (!this.fakeLTDActionAddingToTeamHistoryIsNeeded(LTDUpdate, status, lastCompletedLTDAction)) {
        return null;
      }

      let contentIndex;
      LTDUpdate.contents.some((content, index) => {
        if (RootStore.appStore.typeOfLTDContent(content) === contentType) {
          contentIndex = index;
          return true;
        }
      });

      const isSellAndBuyLTD = LTDUpdate.contents.length === 2;
      const isSecondContent = contentIndex === 1;

      return isSellAndBuyLTD && isSecondContent
        ? ![
            FAKE_LTD_TEAM_TASK_STATUS.SENT,
            FAKE_LTD_TEAM_TASK_STATUS.RECEIVED,
            FAKE_LTD_TEAM_TASK_STATUS.AGREED,
            FAKE_LTD_TEAM_TASK_STATUS.COUNTER_SENT,
            FAKE_LTD_TEAM_TASK_STATUS.COUNTER_RECEIVED,
            FAKE_LTD_TEAM_TASK_STATUS.CANCELLED,
            FAKE_LTD_TEAM_TASK_STATUS.TERMINATED,
            FAKE_LTD_TEAM_TASK_STATUS.TWO_WAY_TERMINATION,
          ].includes(status)
        : true;
    };
  }

  /**
   * Прием истории по LTD из синхройназа
   * @param LTDUpdate - LTD_UPDATE, по которому происходит добавление в историю игры
   * @param LTDUpdates - вся история по LTD
   * @param teamHistoryOfLTDContent - история конкретной LTD и по конкретному контенту
   * @param contentType - тип контента, по которому происходит добавление в историю игры
   * @param isCEOOrCaptainHistory - флаг того, что происходит заполнение истории игры СЕО или капитана
   * @constructor
   */
  LTDToTeamHistoryFromSynchronizeHandler(
    LTDUpdate,
    LTDUpdates,
    teamHistoryOfLTDContent,
    contentType,
    isCEOOrCaptainHistory,
  ) {
    const thisLTDHistory = LTDUpdates.filter((LTDHistoryItem) => LTDUpdate.deal_id === LTDHistoryItem.deal_id);

    const thisLTDCurrentContentHistory = teamHistoryOfLTDContent.filter(
      (LTDHistory) => LTDUpdate.deal_Id === LTDHistory.deal_id,
    );

    const content = RootStore.appStore.LTDContentByType(LTDUpdate.contents, contentType);

    const status = corpStore.fakeLTDTaskStatus(LTDUpdate, content, thisLTDHistory, thisLTDCurrentContentHistory, true);

    return corpStore.LTDUpdateToTeamHistoryFormatAdapter(LTDUpdate, content, status);
  }

  get contentsAndLTDUpdatesIsEqual() {
    return (contents, LTDUpdates) => {
      return (
        _.isEqual(
          RootStore.appStore.LTDContentWithoutExactContents(contents[0]),
          RootStore.appStore.LTDContentWithoutExactContents(contents[1]),
        ) &&
        _.isEqual(
          RootStore.appStore.LTDInfoWithoutContentsAndServerTimeAndMessageOwnerFromLTDUpdate(LTDUpdates[0]),
          RootStore.appStore.LTDInfoWithoutContentsAndServerTimeAndMessageOwnerFromLTDUpdate(LTDUpdates[1]),
        )
      );
    };
  }

  /**
   Возвращает уникальные LTDUpdate'ы для конкретной долгосрочной сделки и для конкретного типа контента
   */
  get uniqueLTDUpdatesByContentType() {
    return (LTDHistory, contentType) => {
      return LTDHistory.filter((LTDUpdate, historyIndex) => {
        const dealId = LTDUpdate.deal_id;
        const content = RootStore.appStore.LTDContentByType(LTDUpdate.contents, contentType);
        if (!content) {
          return false;
        }

        const isUniqueLTDUpdateForContentType = !LTDHistory.some((_LTDUpdate, _historyIndex) => {
          if (LTDUpdate.deal_id !== dealId) {
            return;
          }
          const _content = RootStore.appStore.LTDContentByType(_LTDUpdate.contents, contentType);
          if (
            this.contentsAndLTDUpdatesIsEqual([content, _content], [LTDUpdate, _LTDUpdate]) &&
            historyIndex > _historyIndex
          ) {
            return true;
          }
        });
        return isUniqueLTDUpdateForContentType;
      });
    };
  }

  /**
   Проверка того, что переданный LTDUpdate уникален для истории по LTD
   */
  get isUniqueLTDUpdateByContentType() {
    return (formattedTeamHistoryOfCurrentLTD, LTDUpdate, contentType) => {
      const neededContentByType = RootStore.appStore.LTDContentByType(LTDUpdate.contents, contentType);

      if (!neededContentByType) {
        return false;
      }
      const isUniqueLTDUpdateForContentType = !formattedTeamHistoryOfCurrentLTD.some((LTDHistoryItem) => {
        const historyLTDUpdate = LTDHistoryItem.task_update.originalLTDUpdate;

        const _neededContentByType = RootStore.appStore.LTDContentByType(historyLTDUpdate.contents, contentType);

        return this.contentsAndLTDUpdatesIsEqual(
          [neededContentByType, _neededContentByType],
          [LTDUpdate, historyLTDUpdate],
        );
      });
      return isUniqueLTDUpdateForContentType;
    };
  }

  get fakeLTDTaskAbstractCats() {
    return (LTDContent) => {
      const cats = [];
      const contents = LTDContent.ltd_contents;
      contents.forEach((content) => {
        content.abstract_contents.forEach((abstractCat) => {
          const existingCatInCats = cats.find(
            (similarCats) => similarCats[0].color === abstractCat.color && similarCats[0].gender === abstractCat.gender,
          );
          if (existingCatInCats) {
            existingCatInCats.push(abstractCat);
            existingCatInCats[0].totalCount += abstractCat.count;
            existingCatInCats[0].totalPrice += abstractCat.count * abstractCat.price;
          } else {
            cats.push([
              { ...abstractCat, totalPrice: abstractCat.count * abstractCat.price, totalCount: abstractCat.count },
            ]);
          }
        });
      });
      return cats;
    };
  }

  get LTDHistoryPrice() {
    return (LTDTask) => {
      const { status } = LTDTask;
      if (
        [
          FAKE_LTD_TEAM_TASK_STATUS.AGREED,
          FAKE_LTD_TEAM_TASK_STATUS.SENT,
          FAKE_LTD_TEAM_TASK_STATUS.RECEIVED,
          FAKE_LTD_TEAM_TASK_STATUS.COUNTER_RECEIVED,
          FAKE_LTD_TEAM_TASK_STATUS.COUNTER_SENT,
        ].includes(status)
      ) {
        if (LTDTask.ltd_type === LTD_CONTENT_TYPE.BUY_SELL) {
          const contents = LTDTask.originalLTDUpdate.contents.map((content) => content.abstract_contents);
          return (
            RootStore.appStore.catContentsFullPrice(contents[0]) + RootStore.appStore.catContentsFullPrice(contents[1])
          );
        } else {
          return RootStore.appStore.catContentsFullPrice(LTDTask.content.contents);
        }
      } else if (status === FAKE_LTD_TEAM_TASK_STATUS.ACTION_COMPLETED) {
        const lastCompletedByMeAction = RootStore.appStore.lastCompletedByMeLTDAction(LTDTask.ltd_actions);
        return LTDTask[lastCompletedByMeAction.action_type]?.money || 0;
      } else if ([FAKE_LTD_TEAM_TASK_STATUS.PRETENSION, FAKE_LTD_TEAM_TASK_STATUS.FINE].includes(status)) {
        let price = 0;
        const lastLTDActionWithPretension = RootStore.appStore.lastLTDActionWithSentLiability(LTDTask.ltd_actions);

        if (lastLTDActionWithPretension && lastLTDActionWithPretension.sent_liabilities?.length) {
          lastLTDActionWithPretension.sent_liabilities
            .filter((sent_liability) => {
              if (status === FAKE_LTD_TEAM_TASK_STATUS.PRETENSION) {
                return !sent_liability.is_fine;
              } else {
                return sent_liability.is_fine;
              }
            })
            .forEach((liability) => {
              const liabilityInfo = LTDTask[`${liability.liability_type[0]}_liability`];
              if (!liabilityInfo) {
                return;
              }

              price += RootStore.appStore.LTDFinePrice(
                { ltd_info: LTDTask.originalLTDUpdate },
                liabilityInfo.payment_type,
                liabilityInfo.value,
              );
            });
        }
        return price || 0;
      } else if (status === FAKE_LTD_TEAM_TASK_STATUS.TERMINATED) {
        const oneWayTermination = LTDTask.originalLTDUpdate.one_way_termination;
        if (oneWayTermination?.payment_type === LTD_FINES_PAYMENT.FIXED) {
          return oneWayTermination?.value;
        } else {
          return RootStore.appStore.getFineForDealAmount(oneWayTermination?.value, {
            ltd_info: LTDTask.originalLTDUpdate,
          });
        }
      }
      return 0;
    };
  }

  transportationToTeamHistoryFormatAdapter(data) {
    if (!data) {
      return null;
    }
    data = _.cloneDeep(data);
    const uniqueCatIds = data.cats.slice(0, 3);

    const [groupedCats, price] = RootStore.appStore.groupCats({ contents: data.cats });
    const sortedGroupedCats = groupedCats.sort(catsSort);

    const formattedTask = {
      status: TEAM_TASK_STATUS.CONFIRMED,
      type: TASK_TYPES.FAKE_TRANSPORTATION_TASK,
      stage: SPECIALITIES.CEO,
      move_id: data.move_id,
      server_time: data.server_time,
      specialty_by: SPECIALITIES.CEO,
      visible_to: [SPECIALITIES.CEO],
      visas: { [SPECIALITIES.CEO]: true },
      count: data.cats.length,
      task_id: uniqueCatIds.map((cat) => cat.cat_id).join('_'),
      content: { contents: sortedGroupedCats },
      transportation_status: data.status,
    };

    return {
      server_time: formattedTask.server_time,
      task_update: formattedTask,
    };
  }

  get taskByLotId() {
    return (lotId) => {
      let tasks = [];
      if (RootStore.appStore.meIsCEO) {
        Object.values(this.areas).forEach((area) => {
          tasks.push(...area.tasks);
        });
        tasks.push(...RootStore.appStore.tasks);
      } else {
        tasks = RootStore.appStore.tasks;
      }
      return tasks.find((p) => p.lot_id === lotId);
    };
  }

  get importExportTaskInTeamHistoryAlreadyExist() {
    return (teamHistory, lot_id) => {
      return teamHistory.find((teamHistoryItem) => {
        return (
          [TASK_TYPES.FAKE_EXPORT_TASK, TASK_TYPES.FAKE_IMPORT_TASK].includes(teamHistoryItem.task_update.type) &&
          teamHistoryItem.task_update.lot_id === lot_id
        );
      });
    };
  }

  // Открыть модальное окно для детальной информации по трансферу ↓
  openTransferDetailModal(data) {
    this.transferDetailDataModal = data;
  }

  // Закрыть модальное окно для детальной информации по трансферу ↓
  hideTransferDetailModal() {
    this.transferDetailDataModal = null;
  }

  // ТРАНСПОРТИРОВКА ↓
  // Открыть модальное окно для информации по полученным машинам ↓
  openReceivingCarsModal(data) {
    if (!data) {
      this.receivingCarsModalData = {
        title: 'У вас недостаточно машин',
        desc: '',
        totalPrice: '',
        colorBg: 'red',
      };
    } else {
      const carInfo = {
        carSize: data.cars[0].car_size,
        getType: data.cars[0].get_type,
        count: data.cars.length,
        totalPrice: data.cars.length * this.carCost[data.cars[0].get_type][data.cars[0].car_size],
      };
      // ToDo необходима логика на склонения
      let title = `${carInfo.getType === CAR_ACTION_TYPE.BUY ? 'покупка' : 'аренда'} машин одобрена`;
      let desc = `${carInfo.count} машина на ${CAR_SIZE[carInfo.carSize]} мест`;
      let totalPrice = carInfo.totalPrice;
      this.receivingCarsModalData = {
        title: title,
        desc: desc,
        totalPrice: totalPrice,
        colorBg: 'green',
      };
    }
  }

  // Закрыть модальное окно для информации по полученным машинам ↓
  hideReceivingCarsModal() {
    this.receivingCarsModalData = null;
  }

  // Открыть модальное окно для информации по перевозимым котикам ↓
  openMovingCatsModal(data) {
    let title = '';
    let desc = '';
    let colorBg = '';
    const cats = data.cats.reduce((acc, cat) => {
      const isItSimilarCats = acc.find((similarCats) => {
        return similarCats[0].gender === cat.gender && similarCats[0].color === cat.color;
      });
      if (isItSimilarCats) {
        isItSimilarCats.push(cat);
      } else {
        acc.push([cat]);
      }
      return acc;
    }, []);
    if (RootStore.appStore.meIsCEO) {
      title = 'транспортировка котиков';
      desc = `из города ${this.areaNumByCaptainId(data.sender_city_captain_id)} в город ${this.areaNumByCaptainId(
        data.recipient_city_captain_id,
      )}`;
      colorBg = 'green';
    } else {
      title =
        data.status === TRANSPORTATION_STATUS.TRANSPORTATION
          ? 'сео перевозит ваших котиков'
          : 'сео перевез котиков в ваш город';
    }
    this.movingCatsModalData = {
      title: title,
      desc: desc,
      cats: cats,
      colorBg: colorBg,
    };
  }

  // Закрыть модальное окно для информации по перевозимым котикам ↓
  hideMovingCatsModal() {
    this.movingCatsModalData = null;
  }

  openNotEnoughSeasonForTransferModal(data) {
    this.notEnoughSeasonForTransferModalData = data;
  }

  hideNotEnoughSeasonForTransferModal() {
    this.notEnoughSeasonForTransferModalData = null;
  }

  openNotEnoughSeasonForTransportationModal() {
    this.notEnoughSeasonForTransportationModalData = true;
  }

  hideNotEnoughSeasonForTransportationModal() {
    this.notEnoughSeasonForTransportationModalData = false;
  }

  get thereAreExaminedSicknessCatsInStorageInCorpMode() {
    return (
      RootStore.appStore.isCorpMode &&
      RootStore.appStore.meIsCEO &&
      RootStore.appStore.me.actionLTD.actionDetail.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT &&
      RootStore.appStore.me.actionLTD.cats.find((cat) => {
        if (cat.storageContents.length) {
          return cat.storageContents.find(
            (storageCat) =>
              storageCat.sickness &&
              storageCat.sickness !== SICKNESS.NONE &&
              (RootStore.appStore.isCatExaminationInLTD(storageCat.cat_id) ||
                (RootStore.appStore.me.actionLTD?.actionDetail.action_type !== LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT &&
                  RootStore.appStore.isCatExamined(cat.cat_id))),
          );
        }
      })
    );
  }

  get areaNumByLTDId() {
    return (deal_id) => {
      const ltd = RootStore.appStore.ltdById(deal_id)?.ltd_info;
      if (!ltd) {
        return;
      }

      let areaNum;
      ltd.contents[0].captains.some((captainId) => {
        areaNum = this.areaNumByCaptainId(captainId);
        return areaNum;
      });
      return areaNum;
    };
  }

  get areaByLTDId() {
    return (deal_id) => {
      const areaNum = this.areaNumByLTDId(deal_id);
      if (!areaNum) {
        return;
      }

      return this.areas[areaNum];
    };
  }

  // Получить котят своих компаний ↓
  get corpOurCats() {
    // Копипаст из appStore.get playerCats()
    const result = {};
    Object.values(this.areas).forEach((area) => {
      result[area.area_num] = _.cloneDeep(CATS_TEMPLATE);
      _.find(area.companies, { uuid: area.company_uuid }).default_prices.forEach((cat) => {
        const meCat = _.find(result[area.area_num], { gender: cat.gender, color: cat.color });
        meCat.sell_price = cat.sell_price;
        meCat.buy_price = cat.buy_price;
      });
      area.ourCats.forEach((cat) => {
        if (
          !cat.is_available ||
          _.find(this.operationContent?.contents, (content) => {
            return content.detail?.cat_id === cat.cat_id;
          }) ||
          RootStore.appStore.isAdultCat(cat)
        ) {
          return;
        }
        let meCat = _.find(result[area.area_num], { gender: cat.gender, color: cat.color });
        if (!meCat.contents) {
          meCat.contents = [];
          meCat.count = 0;
        }
        meCat.count++;
        meCat.contents.push(cat);
      });
    });
    return result;
  }

  get allExactCatsOfCorporation() {
    const cats = [];
    Object.values(this.areas).forEach((area) => cats.push(...area.ourCats));
    return cats;
  }

  get areaNumByHouseId() {
    return (house_id) => {
      return Object.keys(this.areas).find((areaNum) =>
        this.areas[areaNum].houses.find((house) => house.house_id === house_id),
      );
    };
  }

  get allHousesOfCorporation() {
    const houses = [];
    Object.values(this.areas).forEach((area) => houses.push(...area.houses));
    return houses;
  }

  // Получить информацию по машинам и котятам в транспортировке из this.transportations ↓
  get transportationInfo() {
    return (moveID) => {
      const result = {
        cars: {
          small: [],
          big: [],
        },
        cats: _.cloneDeep(CATS_TEMPLATE).map((cat) => {
          return {
            color: cat.color,
            gender: cat.gender,
            isVisible: false,
            count: 0,
          };
        }),
      };
      const transportation = _.find(this.transportations, { move_id: moveID });
      transportation.cars.forEach((car) => {
        result.cars[car.car_size].push(car);
      });
      transportation.cats.forEach((cat) => {
        const item = _.find(result.cats, { gender: cat.gender, color: cat.color });
        item.count++;
        item.isVisible = true;
      });
      return result;
    };
  }
  // ОПЕРАЦИИ ↑

  get allActiveAreaNumsOfCEO() {
    return (CEO_UUID) => {
      return this.allActiveCaptainsOfCEO(CEO_UUID).map((captain) => captain.areaNum);
    };
  }

  // Получить текущие машины для транспортировки ↓
  getCurrentCars() {
    if (!this.carsCount) {
      this.carsCount = {
        small: '',
        big: '',
      };
    }
    return this.carsCount;
  }

  // Изменить значение у машины по type ↓
  changeCountCars(type, value) {
    const max = 10;
    if (value > max) {
      value = max;
    }
    if (type === CAR_TYPE.BIG) {
      this.carsCount[CAR_TYPE.SMALL] = '';
    } else {
      this.carsCount[CAR_TYPE.BIG] = '';
    }
    this.carsCount[type] = value;
  }

  // Открыть модальное окно для подтверждения покупки/аренды машины ↓
  openConfirmationActionWithCar({ get_type, car_size, amount }) {
    this.confirmationActionWithCarData = { get_type, car_size, amount };
  }

  // Закрыть модальное окно для подтверждения покупки/аренды машины ↓
  hideConfirmationActionWithCar() {
    this.carsCount = null;
    this.confirmationActionWithCarData = null;
  }

  // Купить/арендовать машины ↓
  receiveCar(get_type, car_size, amount, totalPrice) {
    if (!RootStore.appStore.checkBalance(totalPrice)) {
      RootStore.appStore.notEnoughBalanceModalOpen();
      this.hideConfirmationActionWithCar();
      return;
    }
    RootStore.appStore.sendMessage(MESSAGE_TYPE.RECEIVING_CAR_REQUEST, { get_type, car_size, amount });
    this.hideConfirmationActionWithCar();
  }

  // Получить все мои машины ↓
  get myCars() {
    const result = {
      small: [],
      big: [],
    };
    this._myCars.forEach((car) => {
      result[car.car_size].push(car);
    });
    return result;
  }

  // Получить все мои доступные машины ↓
  get myAvailableCars() {
    const myCars = _.cloneDeep(this.myCars);
    return {
      small: myCars[CAR_TYPE.SMALL].filter((car) => car.is_available),
      big: myCars[CAR_TYPE.BIG].filter((car) => car.is_available),
    };
  }

  // Получить общее колличество свободных мест в моих доступных машинах ↓
  get totalCountPlacesInMyCars() {
    // Количество мест большой + маленькой машины - количество котят погруженных в зону операции
    return (
      this.myAvailableCars[CAR_TYPE.BIG].length * CAR_SIZE[CAR_TYPE.BIG] +
      this.myAvailableCars[CAR_TYPE.SMALL].length * CAR_SIZE[CAR_TYPE.SMALL]
    );
  }

  // Получить остаток колличества свободных мест в моих доступных машинах ↓
  get restCountPlacesInMyCars() {
    let countPlaces = this.totalCountPlacesInMyCars - this.detailInfoCatsForOperation.count;
    if (countPlaces < 0) {
      countPlaces = 0;
    }
    return countPlaces;
  }

  get corporationCaptains() {
    return Object.keys(this.areas).map((key) => ({
      areaNum: parseInt(key),
      ..._.find(this.areas[key].company_players, { specialty: SPECIALITIES.CAPTAIN }),
      captain_name: this.areas[key].captain_name,
      balance: this.areas[key].balance,
      financeHistory: this.areas[key].financeHistory,
      household: this.areas[key].household,
      active: _.find(this.areas[key].companies, { uuid: this.areas[key].company_uuid }).active,
      game_over_season: _.find(this.areas[key].companies, { uuid: this.areas[key].company_uuid }).game_over_season,
      teamLimits: this.areas[key].teamLimits,
    }));
  }

  /**
   * Используется городами, возвращает список игроков без СЕО
   * @return {Array} - массив игроков
   */
  get companyPlayersWithoutCEO() {
    return RootStore.appStore.company_players.filter((player) => {
      return player.specialty !== SPECIALITIES.CEO;
    });
  }

  /**
   * Возвращает всех игроков нашей компании по городам
   * @return {Object} - ключом является номер города, а значением игроки этого города
   */
  get corporationCompanyPlayersByAreaNums() {
    const result = {};
    Object.keys(this.areas).forEach((areaNum) => {
      result[areaNum] = this.areas[areaNum].company_players;
    });
    return result;
  }

  /**
   * Возвращает всех игроков, исключая СЕО, нашей компании по городам
   * @return {Object} - ключом является номер города, а значением игроки этого города
   */
  get corporationCompanyPlayersWithoutCEOByAreaNums() {
    const result = {};
    Object.entries(this.corporationCompanyPlayersByAreaNums).forEach(([areaNum, players]) => {
      result[areaNum] = players.filter((player) => player.specialty !== SPECIALITIES.CEO);
    });
    return result;
  }

  get corporationCompanyPlayers() {
    const result = [];
    Object.keys(this.areas).forEach((key) => {
      result.push(...this.areas[key].company_players);
    });
    return result;
  }

  get ourCatsByAreaNum() {
    return (area_num) => {
      return this.areas[area_num].our_cats;
    };
  }

  get defaultPricesByCompanyUuid() {
    return (companyUuid) => {
      let result;
      Object.values(this.areas).forEach((area) => {
        let _result = area.companies.find((company) => company.uuid === companyUuid);
        if (_result) {
          result = _result.default_prices;
        }
      });
      return result;
    };
  }

  get corpCats() {
    let result = {};
    Object.keys(this.areas).forEach((areaNum) => {
      const area = this.areas[areaNum];
      result[areaNum] = _.cloneDeep(CATS_TEMPLATE);
      this.defaultPricesByCompanyUuid(area.company_uuid).forEach((prices) => {
        const meCat = _.find(result[areaNum], { gender: prices.gender, color: prices.color });
        meCat.sell_price = prices.sell_price;
        meCat.buy_price = prices.buy_price;
      });
      result[areaNum] = result[areaNum].filter((c) => c.sell_price);
    });

    Object.keys(this.areas).forEach((areaNum) => {
      const area = this.areas[areaNum];
      let ourCats = area.ourCats;

      ourCats.forEach((cat) => {
        if (!cat.is_available) {
          return;
        }

        if (RootStore.appStore.isAdultCat(cat)) {
          return;
        }
        if (RootStore.appStore.isCatStorage(cat)) {
          return;
        }
        if (
          RootStore.appStore.currentPlayer?.lot &&
          RootStore.appStore.currentPlayer.lot.seller === RootStore.appStore.me.uuid &&
          RootStore.appStore.currentPlayer.lot.contents.find((c) => c.detail?.cat_id === cat.cat_id)
        ) {
          return;
        }
        const meCat = _.find(result[areaNum], { gender: cat.gender, color: cat.color });
        if (!meCat.contents) {
          meCat.contents = [];
          meCat.count = 0;
        }
        meCat.contents.push(cat);
        meCat.count++;
      });
    });
    return result;
  }

  get findCatByCatIdFromAllAreas() {
    return (cat_id) => {
      for (const area of _.values(this.areas)) {
        const cat = area.ourCats.find((cat) => cat.cat_id === cat_id);
        if (cat) {
          return [cat, area.area_num];
        }
      }
    };
  }

  get areaNumByCatId() {
    return (cat_id) => {
      return this.findCatByCatIdFromAllAreas(cat_id)[1];
    };
  }

  setSicknessOfCatByCatId(cat_id, sickness_status) {
    const cat = this.findCatByCatIdFromAllAreas(cat_id)[0];
    if (cat) {
      cat.sickness = sickness_status;
    }
  }

  addCatIdToExaminedCats(cat_id) {
    this.areas[this.areaNumByCatId(cat_id)].examined_cats_ids.push(cat_id);
  }

  get catIsExaminedById() {
    return (cat_id) => {
      for (const area of _.values(this.areas)) {
        const catSicknessVision = area.examined_cats_ids.find((_cat_id) => _cat_id === cat_id);
        if (catSicknessVision) {
          return true;
        }
      }
    };
  }

  get captainById() {
    return (captainId) => {
      return _.find(this.corporationCaptains, { player_id: captainId });
    };
  }

  changePrivilegesForSpeciality(speciality, areaNum, privileges, removing) {
    const players =
      areaNum === 'all_areas'
        ? this.allActiveAreaNums.map((areaNum) => this.playerBySpecialityAndAreaNum(speciality, areaNum))
        : [this.playerBySpecialityAndAreaNum(speciality, areaNum)];
    const changingPrivileges = [];
    if (!_.isArray(privileges)) {
      privileges = [privileges];
    }
    privileges.forEach((privilege) => {
      players.forEach((player) => {
        changingPrivileges.push([player.player_id, privilege]);
      });
    });
    if (removing) {
      RootStore.appStore.modifyPrivileges([], changingPrivileges);
      return;
    }
    RootStore.appStore.modifyPrivileges(changingPrivileges);
  }

  addPrivilegesForSpeciality(speciality, areaNum, privileges) {
    this.changePrivilegesForSpeciality(speciality, areaNum, privileges);
  }

  deletePrivilegesForSpeciality(speciality, areaNum, privileges) {
    this.changePrivilegesForSpeciality(speciality, areaNum, privileges, true);
  }

  setDelegatingCheckBox(previousValue, player_id, privileges) {
    const modifyingPrivileges = privileges.map((privilege) => [player_id, privilege]);
    if (previousValue) {
      RootStore.appStore.modifyPrivileges([], modifyingPrivileges);
    } else {
      RootStore.appStore.modifyPrivileges(modifyingPrivileges, []);
    }
  }

  get salesInAreasHasAllPrivilegesForSellToCity() {
    const result = {};
    this.allActiveAreaNums.forEach((areaNum) => {
      if (!RootStore.appStore.meIsCEO) {
        return false;
      }
      const sales = corpStore.playerBySpecialityAndAreaNum(SPECIALITIES.SALES, areaNum);
      result[areaNum] = RootStore.appStore.playerHasAllPrivilegesForSellToCity(sales.privileges);
    });
    return result;
  }

  get formattedTeamHistoryByAreaNums() {
    const result = {};
    Object.keys(this.areas).forEach((areaNum) => {
      result[areaNum] = formatTeamHistory(this.areas[areaNum].teamHistory);
    });
    result.ceo = formatTeamHistory(RootStore.appStore.teamHistory);
    return result;
  }

  get teamHistoryBySeasonsByAreaNums() {
    const result = {};
    Object.keys(this.areas).forEach((areaNum) => {
      result[areaNum] = corpStore.areas[areaNum].teamHistoryBySeasons || {};
    });
    result.ceo = RootStore.appStore.teamHistoryBySeasons;
    return result;
  }

  get captainUuidByCEOUuid() {
    return (ceoUuid) => {
      return Object.keys(RootStore.appStore.otherTeamsCEO).find(
        (captainUuid) => RootStore.appStore.otherTeamsCEO[captainUuid] === ceoUuid,
      );
    };
  }

  get CEOUuidByCaptainUuid() {
    return (captainUuid) => {
      return RootStore.appStore.otherTeamsCEO[captainUuid];
    };
  }

  get checkCaptainBalance() {
    return (captainId, price) => {
      return this.captainById(captainId).balance - price >= 0;
    };
  }

  get areaNumByCaptainId() {
    return (captainId) => {
      return this.captainById(captainId)?.areaNum;
    };
  }

  get areaNumByCompanyUuid() {
    return (companyUuid) => {
      return Object.keys(this.areas).find((areaNum) => {
        if (this.areas[areaNum].company_uuid === companyUuid) {
          return areaNum;
        }
      });
    };
  }

  get areaByPlayerId() {
    return (playerId) => {
      let area;
      Object.keys(this.areas).forEach((key) => {
        if (
          this.areas[key].company_players.find((player) => player.player_id === playerId) ||
          this.areas[key].companies.find((player) => player.uuid === playerId)
        ) {
          area = this.areas[key];
        }
      });
      return area;
    };
  }

  get areaNumByPlayerId() {
    return (playerId) => {
      return Object.keys(this.areas).find((key) => _.isEqual(this.areas[key], this.areaByPlayerId(playerId)));
    };
  }

  get companiesWithBlockingInfo() {
    return Object.values(corpStore.areas).filter((area) => area.blockedCompanies && area.blockedByCompanies);
  }

  openCorporationModal() {
    this.corporationModal = true;
  }

  hideCorporationModal() {
    this.corporationModal = false;
  }

  openImportPaymentModal(data) {
    this.importPaymentModalData = data;
  }

  hideImportPaymentModal() {
    this.importPaymentModalData = null;
  }

  openImportExportNotificationModal(data) {
    this.importExportNotificationModalData = data;
  }

  hideImportExportNotificationModal() {
    this.importExportNotificationModalData = null;
  }

  openLimitModal(data) {
    this.limitModalData = {
      isOpen: true,
      ...data,
    };
  }

  hideLimitModal() {
    this.limitModalData = { isOpen: false };
  }

  openCurrentSeasonLimitsModal(data) {
    this.currentSeasonLimitsModalFlag = { isOpen: true, ...data };
  }

  hideCurrentSeasonLimitsModal() {
    this.currentSeasonLimitsModalFlag = {
      isOpen: false,
    };
  }

  get availabilityPrivilegesOfPlayerOfAllAreas() {
    return (speciality, neededPrivileges, all) => {
      const specialitiesHasPrivileges = {};
      this.allActiveAreaNums.forEach((areaNum) => {
        specialitiesHasPrivileges[areaNum] = playerHasPrivileges(
          this.playerBySpecialityAndAreaNum(speciality, areaNum).privileges,
          neededPrivileges,
          all,
        );
      });
      return specialitiesHasPrivileges;
    };
  }

  get allPlayersOfAllAreasHasPrivileges() {
    return (speciality, neededPrivileges, allPrivileges, allPlayers) => {
      const availabilityPrivilegesOfPlayerOfAllAreas = Object.values(
        this.availabilityPrivilegesOfPlayerOfAllAreas(speciality, neededPrivileges, allPrivileges),
      );
      if (allPlayers) {
        return availabilityPrivilegesOfPlayerOfAllAreas.every((playerHasPrivileges) => playerHasPrivileges);
      } else {
        return availabilityPrivilegesOfPlayerOfAllAreas.some((playerHasPrivileges) => playerHasPrivileges);
      }
    };
  }

  get companyHasTeamLimitLocally() {
    return (areaNum, limitType) => {
      for (let season of Object.keys(this.areas[areaNum].teamLimits)) {
        if (this.areas[areaNum].teamLimits[season][limitType].localValue !== null) {
          return true;
        }
      }
      return false;
    };
  }

  get availabilityTeamLimitOfCompaniesOfAllAreas() {
    return (teamLimitType) => {
      const companiesHasTeamLimitByAreaNum = {};
      this.allActiveAreaNums.forEach((areaNum) => {
        companiesHasTeamLimitByAreaNum[areaNum] = this.companyHasTeamLimitLocally(areaNum, teamLimitType);
      });
      return companiesHasTeamLimitByAreaNum;
    };
  }

  get companiesOfAllAreasHasTeamLimit() {
    return (teamLimitType, allCompanies) => {
      const availabilityTeamLimitOfCompaniesOfAllAreas = Object.values(
        this.availabilityTeamLimitOfCompaniesOfAllAreas(teamLimitType),
      );
      if (allCompanies) {
        return availabilityTeamLimitOfCompaniesOfAllAreas.every((companyHasPrivileges) => companyHasPrivileges);
      } else {
        return availabilityTeamLimitOfCompaniesOfAllAreas.some((companyHasPrivileges) => companyHasPrivileges);
      }
    };
  }

  setLocalLimitValue(areaNum, limitType, value, season = RootStore.appStore.currentSeason) {
    this.areas[areaNum].teamLimits[season][limitType].localValue = value;
  }

  resetLocalLimitCheckBox() {
    Object.values(this.areas).forEach((area) => {
      for (let i = 0; i < RootStore.appStore.turnDurationsSec.length; i++) {
        Object.values(area.teamLimits[i]).forEach((teamLimit) => {
          teamLimit.localValue = teamLimit.serverValue;
        });
      }
    });
  }

  sendCorpLimits(currentArea = null) {
    const dataForSend = {};
    this.allActiveAreaNums.forEach((areaNum) => {
      const areaKey = !currentArea || currentArea === 'all_areas' ? areaNum : currentArea;
      const teamLimitsOfArea = this.areas[areaKey].teamLimits;
      const companyUuid = this.currentCompanyByAreaNum(areaKey).uuid;

      // Клонируем, чтобы избежать ссылочности
      dataForSend[companyUuid] = _.cloneDeep(teamLimitsOfArea);
      for (let season of Object.keys(teamLimitsOfArea)) {
        Object.keys(teamLimitsOfArea[season]).forEach((teamLimitType) => {
          dataForSend[companyUuid][season][teamLimitType] = teamLimitsOfArea[season][teamLimitType].localValue;
        });
      }
    });
    RootStore.appStore.sendMessage(MESSAGE_TYPE.SET_CORP_LIMIT, {
      limits: dataForSend,
    });
  }

  resetLimits(limit, area) {
    let areas;
    if (area === 'all_areas' || area === undefined) {
      areas = this.allActiveAreaNums;
    } else {
      areas = area;
    }
    for (let limitArea of areas) {
      for (let season of Object.keys(this.areas[limitArea].teamLimits)) {
        this.areas[limitArea].teamLimits[season][limit].localValue = null;
        this.areas[limitArea].teamLimits[season][limit].serverValue = null;
      }
    }
    this.sendCorpLimits();
  }

  setLocalLimitCheckboxValue(currentArea, limitType, previousValue, season = RootStore.appStore.currentSeason) {
    const value = !previousValue;
    let areaNums;
    let settingValue;
    if (currentArea === 'all_areas') {
      areaNums = this.allActiveAreaNums;
    } else {
      areaNums = [currentArea];
    }
    if (value || previousValue === null) {
      settingValue = 0;
    } else {
      settingValue = null;
    }
    areaNums.forEach((areaNum) => {
      if (settingValue !== null && this.teamLimitsByAreaNums[areaNum][season][limitType].serverValue) {
        return;
      }
      this.setLocalLimitValue(areaNum, limitType, settingValue, season);
    });
  }

  // Получить лимиты всех капитанов в виде словаря, где ключом будет areaNum ↓
  get teamLimitsByAreaNums() {
    const result = {};
    this.corporationCaptains.forEach((captain) => {
      result[captain.areaNum] = captain.teamLimits;
    });
    return result;
  }

  // Получить определенный лимит определенного города по areaNum и limitType ↓
  get teamLimitByAreaNumAndLimitType() {
    return (areaNum, limitType, localValue, season) => {
      const teamLimit = this.teamLimitsByAreaNums[areaNum][season][limitType];
      if (localValue) {
        return teamLimit.localValue;
      } else {
        return teamLimit.serverValue;
      }
    };
  }

  get noActiveLimitsOfAllAreaNums() {
    return !Object.values(LIMITS_INFO)
      .filter((checkBoxesTypes) => (checkBoxesTypes.visibled ? checkBoxesTypes.visibled() : true))
      .some((checkBoxesType) =>
        this.allActiveAreaNums.some((areaNum) => {
          return this.companyHasTeamLimitLocally(areaNum, checkBoxesType.limitType);
        }),
      );
  }

  // Получить типы моих активных лимитов(для капитана) ↓
  get activeLimitsTypes() {
    const currentSeasonLimits = this.teamLimits[RootStore.appStore.currentSeason];
    if (currentSeasonLimits !== undefined) {
      return Object.keys(currentSeasonLimits).filter((limitType) => currentSeasonLimits[limitType] !== null);
    }

    return [];
  }

  get currentTaskExceededLimits() {
    if (!RootStore.appStore.hasSpecialities([SPECIALITIES.CEO, SPECIALITIES.CAPTAIN])) {
      return null;
    }
    let exceededLimits;
    const currentTask = RootStore.appStore.currentTaskModalData;
    if (currentTask?.limit_content) {
      exceededLimits = [];
      Object.entries(currentTask.limit_content).forEach(([limitType, value]) => {
        exceededLimits.push([limitType, value]);
      });
    }
    return exceededLimits;
  }

  budgetAllocation(captainId, money, allocate) {
    RootStore.appStore.sendMessage(MESSAGE_TYPE.BUDGET_ALLOCATION, {
      to: captainId,
      delta: allocate ? -money : +money,
    });

    let allocationType = allocate
      ? ALLOCATION_NOTIFICATION_TYPES.YOU_ALLOCATED_BUDGET
      : ALLOCATION_NOTIFICATION_TYPES.YOU_WITHDRAWN_BUDGET;
    this.openBudgetAllocationNotificationModal({
      captainId,
      money,
      type: allocationType,
    });
  }

  openBudgetAllocationNotificationModal(data) {
    this.budgetAllocationNotificationModal = data;
  }

  hideBudgetAllocationNotificationModal() {
    this.budgetAllocationNotificationModal = null;
  }

  openBudgetAllocationConfirmationModal(data) {
    this.budgetAllocationConfirmationModalData = data;
  }

  hideBudgetAllocationConfirmationModal() {
    this.budgetAllocationConfirmationModalData = null;
  }
}

export default new CorpStore();
