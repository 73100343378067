import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import WatcherModalIcon from '../icons/modal/WatcherModalIcon';
import trainingStore from '../../store/trainingStore';
import { AI_TYPE, SPECIALITIES } from '../../common/constants';

function UserListComponent({ player }) {
  const Logo = player.logo;
  const { appStore } = RootStore;
  const companyIsBlockedAndIsActive = appStore.companyIsBlocked(player.uuid) && player.active;
  return (
    <div key={player.uuid} className="user-list_item">
      <div className="user-list_item-block">
        <Logo /> {player.name}
        {appStore.isSpectatorModeOn &&
          !appStore.isCorpMode &&
          player.active &&
          (player.ai_type === AI_TYPE.HUMAN || window.botSpectatorModeOn) && (
            <div className="user-list_item__watcher" onClick={() => appStore.changePlayerSpectator(player.uuid)}>
              <WatcherModalIcon fill={appStore.spectatorPlayerUid === player.uuid ? '#C6A788' : '#000'} />
            </div>
          )}
      </div>
      {!trainingStore.launched &&
        !appStore.isSingleMode &&
        appStore.me.role !== player.role &&
        appStore.hasSpecialities([SPECIALITIES.CAPTAIN, SPECIALITIES.CEO]) &&
        !corpStore.currentAreaNum &&
        !(appStore.isSpectatorModeOn && appStore.isCorpMode) && (
          <TextButton
            customClass="user-list_item-action"
            title={companyIsBlockedAndIsActive ? 'Разблокировать' : 'Заблокировать'}
            color={companyIsBlockedAndIsActive ? 'green' : 'red'}
            onClick={() => {
              if (appStore.companyIsBlocked(player.uuid)) {
                appStore.unBlockCompany(player.uuid);
              } else {
                appStore.blockCompany(player.uuid);
              }
            }}
            isDisabled={
              !player.active ||
              (appStore.hasSpecialities(SPECIALITIES.CAPTAIN) &&
                appStore.companyIsBlocked(player.uuid) &&
                !appStore.companyIsBlockedByMe(player.uuid))
            }
          />
        )}
    </div>
  );
}

export default observer(UserListComponent);
